.wrp-player-image-overlay {
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    object-fit: contain;
  }
}

.wrp-player-embed-overlay {
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    object-fit: contain;
    background-color: #fff;
  }
}

// Styling videoJS selectors
// stylelint-disable selector-class-pattern
[data-wrp-type='image']:not(.wrp-player-story-started) {
  .vjs-big-play-button,
  .vjs-poster {
    z-index: 10;

    // Need to override !important in vjs styles
    // stylelint-disable-next-line declaration-no-important
    display: block !important;

    // For the embed player
    visibility: visible;
  }

  .wrp-player-image-overlay,
  .wrp-player-text-overlay .wrp-player-embed-overlay {
    visibility: hidden;
    opacity: 0;
  }
}

[data-wrp-type='image'].wrp-player-story-started {
  &,
  .vjs-poster {
    // Need to override !important in vjs styles
    // stylelint-disable-next-line declaration-no-important
    background-image: none !important;
  }

  .vjs-wrp-choices-bar__container.invisible {
    // Need to override !important in vjs styles
    // stylelint-disable-next-line declaration-no-important
    visibility: visible !important;
    opacity: 1;
  }
}

// stylelint-enable selector-class-pattern
