.modal-upload-video {
  .upload-a-file-wrapper {
    border: 1px dashed $grey;
    background-color: $white;
    text-align: center;
    border-radius: 10px;

    &.highlight {
      border-color: $success-dark;
      background-color: $success-light;
    }
  }

  .modal-body-container-wrapper {
    margin-top: 47px; // room for close btn

    .modal-body-container {
      max-width: 700px;
      margin: auto;
      text-align: center;
    }
  }

  .text-help--blue {
    color: $blue;
  }

  .external-links {
    .external-link {
      .svg-icon-wrapper {
        background: $white;
        border-radius: 10px;
        border: 1px solid $grey-light;
      }
    }
  }
}

.btn-upload-modal-trigger {
  display: none;
}

.wrp-file-upload--empty {
  .btn-upload-modal-trigger {
    display: block;
  }
}

// HACK
// fixes for displaying modal correctly on iOS mobile devices
@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .wrp-studio--open.modal-open {
    .js-load-modal-content {
      width: 100%;

      .wrp-tabs {
        display: none;
      }
    }

    .wrp-sidebar-form .wrp-sidebar-form__fields + div {
      display: none;
    }
  }
}
