$transition-box-shadow: box-shadow 0.5s ease;

.wrp-card {
  .wrp-card-heading {
    display: flex;
    align-items: center;
    justify-content: center;

    .wrp-card-heading__text {
      font-weight: lighter;
    }

    &--button {
      justify-content: flex-start;

      .wrp-card-heading__text {
        display: flex;
        flex-basis: 100%;
        justify-content: center;
        padding-right: 2.5rem;
        margin-top: 0;
      }
    }
  }

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  // TODO: These should be elements, not modifiers
  // E.g. `__project`, `__project-templates`
  &--project {
    .wrp-card-body {
      display: flex;

      &__text {
        flex-basis: 100%;
      }
    }

    .card-footer {
      border-top: 0;
      background: $white;
      padding: 1em;
      font-size: 15px;
    }
  }

  &--project-templates {
    border-radius: 1.25rem;
    box-shadow: 0 3px 3px $grey-light;
    transition: $transition-box-shadow;

    &:hover {
      transition: $transition-box-shadow;
      box-shadow: 0 15px 15px $grey-light;
    }

    .card-body {
      padding-bottom: 2rem;
    }

    .embed-responsive-item {
      border-radius: 1.25rem 1.25rem 0 0;
    }

    .card-footer {
      background-color: $white;
      border-top: 0;
      padding: 0 1.25rem 2rem;
      border-radius: 1.25rem;
    }
  }
}
