// Styling Ransack's selector
// stylelint-disable-next-line selector-class-pattern
.sort_link {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  &:not(.no-default),
  &:not(.no-default):hover,
  &:not(.no-default):focus {
    color: inherit;
  }

  svg {
    // Slight shift for visual alignment
    position: relative;
    top: (1em / 16);

    // Ensure size doesn't depend on SVG size
    width: 1rem;
    height: 1rem;

    // Set color only on active sorting
    // (icon also provides a shape change)
    fill: $link-color;
  }

  [role='presentation'] {
    // Slightly lower opacity to match color to text
    // due to the arrows being solid shapes
    opacity: 0.9;
    fill: currentColor;
  }
}

.wrp-column--numeric {
  text-align: right;
  font-variant-numeric: tabular-nums;
}
