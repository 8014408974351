// stylelint-disable selector-class-pattern
.wrp-inline-checkboxes .form-check {
  padding-right: map-get($spacers, 2);
}

.flex-space-evenly {
  justify-content: space-evenly;
}

.flex-align-items-center {
  align-items: center;
}

.w-auto {
  width: auto;
}

// TODO: Solves overlay z-index issue with modals. Need a way of solving.
.z-index-1050 {
  z-index: 1050;
}

.w-52 {
  width: 52%;
}

.border {
  &__bottom {
    border-bottom: 1px solid $gray-300;
  }
}

.point-in-video-select {
  width: 140px;
}

.content-width-auto {
  width: auto;
  display: inline-block;
}
