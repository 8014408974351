@mixin line-clamp($number-of-lines, $include-box-setup: true) {
  // line-clamp needs some webkit prefixes to work, even in Firefox XD
  // stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix
  @if $include-box-setup {
    // -webkit-line-clamp needs a display `-webkit-box`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    // and doesn't hide the rest of the text
    overflow: hidden;
  }
  -webkit-line-clamp: $number-of-lines;
  line-clamp: $number-of-lines;
  // stylelint-enable value-no-vendor-prefix, property-no-vendor-prefix
}

@mixin box-with-clamp(
  $number-of-lines: 5,
  $line-height: $island-line-height,
  $include-box-setup: true
) {
  @include line-clamp($number-of-lines, $include-box-setup);
  // Revert to content-box so that the max-height
  // only touches the content
  box-sizing: content-box;
  max-height: calc(#{$number-of-lines * $line-height} + 0.7rem);
}
