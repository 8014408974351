.wrp-banners__upgrade {
  background-color: $dark;
  color: $light;
  padding: 0.75rem;
  border-radius: 0.25rem;

  a,
  svg {
    color: $orange;
  }

  svg {
    min-width: 2rem;
    margin: 0.25rem;
  }
}
