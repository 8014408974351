// Between styling CodeMirror and the IDs in FountainJS CSS
// it's faster to disable Stylelint altogether on this file
// stylelint-disable
.wrp-script-editor {
  // Limit the height of the editor as it blows too big on Safari
  &,
  & ~ .CodeMirror:not(.CodeMirror-fullscreen) {
    // max-height, which is the actual intent
    // breaks the vertical scrolling, so
    // leaving it as height
    height: 60vh;
  }
}

// Fix the positioning inside CodeMirror's bottom sidebar
.CodeMirror {
  padding: 0;
}

.CodeMirror-scroll {
  padding: map-get($spacers, 2);
}

#workspace #script.dpi100.us-letter .page {
  min-height: 0;
  line-height: 1.55;
  &:not(.page-title) {
    > * {
      margin-bottom: 1em;
    }
  }
}

.CodeMirror-fullscreen {
  .CodeMirror-sizer {
    max-width: 75ch;
    // Override Codemirrors styles set in HTML
    margin-inline: auto !important;
  }
  ~ .editor-preview-side #workspace #script.dpi100.us-letter .page {
    // Get a ratio that approximates A4 as least
    min-height: calc((50vw - 3rem) * 297 / 210);
    padding: 10% 12%;
  }
}

.editor-toolbar {
  .editor-toolbar__info {
    position: absolute;
    top: 50%;
    right: 1em;
    transform: translateY(-50%);
  }

  &:not(.fullscreen) {
    .editor-toolbar__info {
      display: none;
    }
  }
}

.CodeMirror:not(.CodeMirror-fullscreen)
  #workspace
  #script.dpi100.us-letter
  .page {
  padding: map-get($spacers, 4);

  &:not(.title-page) {
    min-height: 0;
  }

  * {
    font-size: (14rem / 16);
  }
}

.wrp-script-editor-info {
  // Prevent the editor info from taking the whole width
  max-width: (200rem / 16);
  overflow: hidden;

  // and clip the text
  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
// stylelint-enable
