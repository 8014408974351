.wrp-watch-player-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 480px;
  background: $black;

  @include media-breakpoint-up(sm) {
    min-height: 0;
  }

  &.wrp-embed-player {
    min-height: 0;
  }

  &--no-logo {
    // TODO: Refactor so the JS does not include the button
    // TODO: Rename class in JS to match the pattern
    // stylelint-disable-next-line selector-class-pattern
    .vjs-wrp-control-logo-button {
      display: none;
    }
  }

  /***
    hide all the player controls elements except the fullscreen button, which should align to right side:
  */
  &--no-controls {
    .vjs-control {
      display: none;
    }

    .vjs-play-control {
      display: none;
    }

    .vjs-wrp-control-step-backward {
      display: none;
    }

    .vjs-wrp-control-step-forward {
      display: none;
    }

    .vjs-wrp-control-logo-button {
      display: none;
    }

    .vjs-wrp-fullscreen {
      margin: auto 0.75rem auto auto;
    }
  }
}

.wrp-watch-player {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;

  @include media-breakpoint-up(md) {
    min-height: 100vh;
  }
}

.wrp-watch-player-loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background: theme-color(secondary);
}

.wrp-watch-video {
  display: block;
  width: 100%;
  height: 100%;
}

.wrp-watch-meta {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: (12rem / 16) 0;
  border-bottom: 1px solid $grey-light;
}

.wrp-watch-meta-created-at {
  font-size: (14rem / 16);
  font-weight: lighter;
  color: $grey;
}

.wrp-watch-share {
  display: flex;
  justify-content: flex-end;

  &-btn {
    display: flex;
    align-items: center;
    align-self: flex-end;
    font-size: 14rem / 16;
    font-weight: 500;
    color: $grey-island;

    @include media-breakpoint-down(xs) {
      justify-content: center;
      width: 100%;
      margin-top: 1.2rem;
    }

    svg {
      width: (15rem / 16);
      height: (16rem / 16);
      margin-right: 7px;
    }
  }
}

.wrp-watch-author {
  margin-top: 1.5rem;
  font-size: 1rem;
  font-weight: lighter;
  color: $grey;
}

.wrp-watch-watermark {
  position: absolute;
  top: 2%;
  left: 2%;
  z-index: 1;
  width: (250 / 1080) * 100%;
  padding-bottom: 8%;
  opacity: 0.67;

  svg {
    width: 100%;
    height: auto;
  }
}

// HACK
// tweaks for iOS mobile devices
@media (orientation: landscape) {
  @supports (-webkit-touch-callout: none) {
    .wrp-watch-player-wrapper {
      .embed-responsive {
        max-height: fill-available;
      }
    }
  }
}

// Vertical video and cinema ratios not included in Bootstrap default styles
// 16x9, 21x9, 4x3, 1x1 defined in Bootstrap.css
.embed-responsive-9by16::before {
  padding-top: 177.77%;
}

.embed-responsive-2by1::before {
  padding-top: 50%;
}

.embed-responsive-185by1::before {
  padding-top: 54.05%;
}

.embed-responsive-239by1::before {
  padding-top: 42.07%;
}

// Properties for the full page watch page to handle background and player sizing
.wrp-watch-page-player-bg {
  background: $black;
}

.wrp-watch-page-wrapper-aspect {
  width: auto;
  margin: auto;

  &-16by9 {
    max-height: 100vh;
    max-width: calc(100vh * 16 / 9);
  }

  &-9by16 {
    max-height: 100vh;
    max-width: calc(100vh * 9 / 16);
  }

  &-1by1 {
    max-height: 100vh;
    max-width: 100vh;
  }

  &-4by3 {
    max-height: 100vh;
    max-width: calc(100vh * 4 / 3);
  }

  &-2by1 {
    max-height: 100vh;
    max-width: calc(100vh * 2);
  }

  &-185by1 {
    max-height: 100vh;
    max-width: calc(100vh * 1.85);
  }

  &-239by1 {
    max-height: 100vh;
    max-width: calc(100vh * 2.39);
  }
}
