.wrp-modal-with-loading {
  &[aria-busy]:not(.wrp-load-modal-content__hidden) {
    .wrp-modal-with-loading__loader {
      @include loader-loading;
    }

    .wrp-modal-with-loading__content {
      display: none;
    }
  }

  &:not([aria-busy]) {
    .wrp-modal-with-loading__loader {
      display: none;
    }
  }

  &:not(.wrp-modal-with-loading--has-error) {
    .wrp-modal-with-loading__error {
      display: none;
    }
  }

  &.wrp-modal-with-loading--has-error {
    .wrp-modal-with-loading__content {
      display: none;
    }
  }
}

.wrp-load-modal-content__hidden {
  .wrp-modal-with-loading__loader {
    display: none;
  }
}

.wrp-iframe-modal {
  // More than 80% so it displays OK narrow portrait screens
  width: 90%;
  margin-right: auto;
  margin-left: auto;

  // Overriding Bootstrap's styles
  // stylelint-disable-next-line selector-class-pattern
  .modal-content {
    background: $grey-lighter;
    filter: $iframe-modal-filter;
    border: 0;
    border-radius: 1rem;

    &--no-opacity {
      background: $js-modal-bg;
    }
  }

  &--fullscreen {
    width: 90%;
    max-width: none;
    margin: 20px auto;

    @include media-breakpoint-up(xl) {
      width: 85%;
    }

    .modal-content {
      height: 100%;
    }

    .modal-body {
      overflow-y: auto;
    }
  }
}

.modal-aspect-16by9 {
  max-width: calc(
    (
        100vh - #{2 * $modal-dialog-margin-y-sm-up} - #{map-get($spacers, 5)} -
          #{2 * $modal-inner-padding}
      ) * 16 / 9
  );
}

.modal-aspect-9by16 {
  max-width: calc(
    (
        100vh - #{2 * $modal-dialog-margin-y-sm-up} - #{map-get($spacers, 5)} -
          #{2.05 * $modal-inner-padding}
      ) * 9 / 16
  );
}

.modal-aspect-1by1 {
  max-width: calc(
    (
      100vh - #{2 * $modal-dialog-margin-y-sm-up} - #{map-get($spacers, 5)} - #{2 *
        $modal-inner-padding}
    )
  );
}

.modal-aspect-4by3 {
  max-width: calc(
    (
        100vh - #{2 * $modal-dialog-margin-y-sm-up} - #{map-get($spacers, 5)} -
          #{2 * $modal-inner-padding}
      ) * 4 / 3
  );
}

.modal-aspect-2by1 {
  max-width: calc(
    (
        100vh - #{2 * $modal-dialog-margin-y-sm-up} - #{map-get($spacers, 5)} -
          #{2 * $modal-inner-padding}
      ) * 2 / 1
  );
}

.modal-aspect-185by1 {
  max-width: calc(
    (
        100vh - #{2 * $modal-dialog-margin-y-sm-up} - #{map-get($spacers, 5)} -
          #{2 * $modal-inner-padding}
      ) * 1.85 / 1
  );
}

.modal-aspect-239by1 {
  max-width: calc(
    (
        100vh - #{2 * $modal-dialog-margin-y-sm-up} - #{map-get($spacers, 5)} -
          #{2 * $modal-inner-padding}
      ) * 2.39 / 1
  );
}
