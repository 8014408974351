@each $spacer-name, $space in $spacers {
  .rythm-#{$spacer-name} {
    > * {
      margin-bottom: 0;
    }

    > * + * {
      margin-top: $space;
    }
  }

  .rythm-x-#{$spacer-name} {
    > * {
      margin-right: 0;
    }

    > * + * {
      margin-left: $space;
    }
  }
}
