.wrp-timer-transparent {
  position: absolute;
  bottom: 0;
  z-index: 1;
  display: block;
  width: 100%;
  height: 5px;

  .wrp-timer-left {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 100%;
    transition: width 1s linear, opacity 0.5s ease;
  }
  .wrp-timer-right {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 100%;
    transition: width 1s linear, opacity 0.5s ease;
  }
}

.wrp-timer-colors {
  position: absolute;
  bottom: 0;
  z-index: 1;
  display: block;
  width: 100%;
  height: 0.7vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  transition: background-size 1s linear;

  // Classes derived from Rails values
  // stylelint-disable-next-line selector-class-pattern
  .bottom_banner & {
    bottom: 100%;
  }

  // stylelint-disable-next-line selector-class-pattern
  .bottom_buttons & {
    position: static;
    margin-inline: auto;
    width: 50%;
    height: 0.5vw;

    @media (min-width: 1440px) {
      height: (7rem / 16);
    }

    border-radius: 10px;
  }
}
