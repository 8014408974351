[aria-expanded='false'] {
  .wrp-hidden-when-not-expanded {
    display: none;
  }
}

[aria-expanded='true'] {
  .wrp-hidden-when-expanded {
    display: none;
  }
}
