@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Raleway');

// First load functions, variables and mixins
@import 'abstract';

// Then generic styles, libraries and resets
@import 'generic';

// And finally the components
@import 'components';

@import '~bootstrap/scss/print';

@import 'extra';
