.wrp-badge {
  &--primary {
    &::before {
      content: '';
      width: 50px;
      height: 25px;
      margin-left: 10px;
      background-image: svg-load('../images/badges/badge-new.svg');
      display: block;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
    }

    &__absolute {
      &::before {
        position: absolute;
        left: 180px;
      }
    }
  }

  &--secondary {
    color: $blue;
    background-color: none;
    border: 1px solid $blue;
    border-radius: 1em;
    padding: 0 0.5em;
  }

  &--word-wrap {
    text-align: left;
    white-space: normal;
  }

  &--blue-border {
    color: $blue;
    background: none;
    border: 1px solid $blue;
    border-radius: 1em;
  }
}
