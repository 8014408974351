.select2-container--bootstrap {
  .select2-selection {
    border-color: $input-border-color;
  }

  .select2-selection--multiple {
    .select2-selection__choice__remove {
      float: right;
      margin-right: 0;
      border: 0;
      margin-left: 3px;
      font-size: 26px;
      line-height: 22px;
      padding-right: 0;
    }
  }
}
