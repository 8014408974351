.wrp-conditional-link-field {
  $row-gap: map-get($spacers, 2);

  display: grid;
  align-items: end; // Aligns the fields with each other if label reflows on two lines
  column-gap: map-get($spacers, 3);
  row-gap: $row-gap;

  // https://smolcss.dev/#smol-css-grid
  grid-template-columns: repeat(auto-fit, minmax(#{'min(100%, 20ch)'}, 1fr));

  // Styling Bootstrap's class
  // stylelint-disable-next-line selector-class-pattern
  .form-group {
    margin-bottom: 0;
  }

  .wrp-conditional-link-field__external-url {
    grid-column-start: 1;
    grid-column-end: -1;
    margin-top: -$row-gap;

    // Styling Bootstrap's class
    // stylelint-disable selector-class-pattern
    > .show,
    > .collapsing {
      margin-top: $row-gap;
    }
    // stylelint-enable selector-class-pattern
  }
  // Ignore a couple of divs to let the elements make part of the grid
  .wrp-conditional-link-field__links-to {
    display: contents;
  }
}
