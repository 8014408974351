// Styling the pickr library
// stylelint-disable selector-class-pattern
.pickr .pcr-button {
  overflow: hidden;
  border: solid 1px $input-border-color;
  &::after {
    border-radius: 0;
  }

  &.clear {
    &::after {
      background-color: transparent;
    }

    background-color: $border-color;
    background-image: none;
  }

  &,
  &.clear {
    &:focus {
      border: solid 1px $input-focus-border-color;
      box-shadow: 0 0 0 1px $input-focus-border-color;
    }
  }
}

.pcr-app .pcr-palette,
.pcr-app .pcr-hue {
  &:focus {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.85),
      0 0 0 4px $input-border-color;
  }

  &:focus:not(.focus-visible) {
    box-shadow: none;
  }
}

.wrp-color-picker-inline {
  .pickr {
    // Align the color picker better with the label
    // necessary regardless of `flex` or `inline-block`
    // display, likely due to the font metrics
    position: relative;
    bottom: 0.125em;
    display: inline-block;
    margin-left: map-get($spacers, 3);
    vertical-align: middle;
  }
}

.pcr-app .pcr-interaction {
  position: relative;
  z-index: 0;
  .pcr-save {
    margin-left: auto;
    @include button-variant(theme-color(primary), theme-color(primary));
    @include button-focus(theme-color(primary));
  }

  .pcr-result {
    color: $input-color;
    background-color: $input-bg;
    @include form-control-focus;
  }

  .pcr-clear {
    position: relative;
    z-index: 1;
    padding-inline: 1em;

    @include button-outline;
    border: solid 1px $input-border-color;
  }

  .pcr-cancel {
    background: transparent;
    @include wrp-link();
    @include wrp-link--reversed();
    @include wrp-link-theme(
      $text-muted,
      $focus-background-color: transparent,
      $focus-color: $text-muted
    );

    &:focus {
      outline-color: $text-muted;
      box-shadow: none;
    }
  }
}
// stylelint-enable selector-class-pattern
