/* dock */
#dock
{
    margin: 0;
}

#dock header
{
    margin-bottom: 25px;
}

#dock header h1
{
    background: url('../vendor/fountainjs/app/assets/images/fountain-24.png') 0 9px no-repeat;
    color: #fff;
    font-size: 16px;
    margin: 0;
    padding-left: 34px;
}

#dock blockquote { background: #fff; margin: 0; padding: 10px; }
#dock p.more-information { font-size: 12px; margin: 5px 0 25px 0; padding: 0; text-align: right; }
#dock #file-api { background: #fff; border: 3px dashed #454545; display: none; margin-top: 75px; text-align: center; }
#dock #file-api.over { border-color: #ED303C; cursor: pointer; }
#dock #file-api p { font-weight: bold; margin: 125px auto; }
#dock p.error { background: #fff url('../vendor/fountainjs/app/assets/images/warning.png') 10px 10px no-repeat; font-weight: bold; margin-top: 75px; padding: 15px 15px 15px 50px; }

/* workspace */
#workspace
{
    color: #333333;
    float: left;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    position: relative;
    width: 100%;
}

#workspace header.dimmed
{
    background-color: transparent;
}

#workspace header.dimmed p
{
    color: #333333;
    font-weight: bold;
}


#workspace header ul,
#workspace header li,
#workspace header p
{
    display: inline;
    float: left;
    margin: 0;
    padding: 0;
}

#workspace header p
{
    color: #fff;
    font-size: 15px;
    height: 20px;
    line-height: 20px;
    margin-top: 10px;
    text-align: center;
    width: 44%;
}

#workspace header ul
{
    padding: 0;
    width: 27%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

#workspace #toolbar { float: right; margin-right: 3px; }
#workspace #inspector li, #workspace #toolbar li { height: 16px; line-height: 16px; margin: 12px 12px 12px 0; position: relative; width: 16px; }
#workspace #toolbar li { float: right; margin: 12px 0 12px 12px; text-align: right; }
#workspace #inspector li a, #workspace #toolbar li a { cursor: pointer; color: #3C3D36; display: block; height: 16px; text-indent: 16px; overflow: hidden; width: 16px; }
#workspace #inspector li a:hover, #workspace #toolbar li a:hover { text-decoration: none; }

#workspace #inspector li a:hover:after, #workspace #toolbar li a:hover:after
{
    background: #ED303C;
    border-radius: 4px;
    color: #fff;
    content: attr(data-tooltip);
    left: -5px;
    padding: 3px 7px 4px 30px;
    position: absolute;
    text-align: left;
    text-indent: 0;
    top: -3px;
}

/* toolbar */
#workspace #toolbar li a:hover:after { background-position: 5px 3px; background-repeat: no-repeat; }

#workspace #toolbar li.resize { background: url('../vendor/fountainjs/app/assets/images/toolbar/resize.small.png') top left no-repeat; }
#workspace #toolbar li.resize:hover { background-image: url('../vendor/fountainjs/app/assets/images/toolbar/resize.small-hover.png'); }
#workspace #toolbar li.resize.large { background: url('../vendor/fountainjs/app/assets/images/toolbar/resize.large.png') top left no-repeat; }
#workspace #toolbar li.resize.large:hover { background-image: url('../vendor/fountainjs/app/assets/images/toolbar/resize.large-hover.png'); }
#workspace #toolbar li.resize a:hover:after { background-image: url('../vendor/fountainjs/app/assets/images/toolbar/resize.small-hover.png'); width: 78px; }
#workspace #toolbar li.resize.large a:hover:after { background-image: url('../vendor/fountainjs/app/assets/images/toolbar/resize.large-hover.png'); }

#workspace #toolbar li.dim { background: url('../vendor/fountainjs/app/assets/images/toolbar/dim.reduce.png') top left no-repeat; }
#workspace #toolbar li.dim:hover { background-image: url('../vendor/fountainjs/app/assets/images/toolbar/dim.reduce-hover.png'); }
#workspace #toolbar li.dim.increase { background: url('../vendor/fountainjs/app/assets/images/toolbar/dim.increase.png') top left no-repeat; }
#workspace #toolbar li.dim.increase:hover { background-image: url('../vendor/fountainjs/app/assets/images/toolbar/dim.increase-hover.png'); }
#workspace #toolbar li.dim a:hover:after { background-image: url('../vendor/fountainjs/app/assets/images/toolbar/dim.reduce-hover.png'); width: 83px; }
#workspace #toolbar li.dim.increase a:hover:after { background-image: url('../vendor/fountainjs/app/assets/images/toolbar/dim.increase-hover.png'); }

#workspace #toolbar li.dock { background: url('../vendor/fountainjs/app/assets/images/toolbar/dock.png') top left no-repeat; }
#workspace #toolbar li.dock:hover { background-image: url('../vendor/fountainjs/app/assets/images/toolbar/dock-hover.png'); }
#workspace #toolbar li.dock a:hover:after { background-image: url('../vendor/fountainjs/app/assets/images/toolbar/dock-hover.png'); width: 90px; }
#workspace #toolbar li.dock.over { background: url('../vendor/fountainjs/app/assets/images/toolbar/dock-drop.png') no-repeat; cursor: pointer; }

/* inspector */
#workspace #inspector li a:hover:after { background-position: 5px 3px; background-repeat: no-repeat; }
/*
#workspace #inspector li.fountain { background: url('../vendor/fountainjs/app/assets/images/toolbar/fountain.png') top left no-repeat; }
#workspace #inspector li.fountain:hover { background-image: url('../vendor/fountainjs/app/assets/images/toolbar/fountain-hover.png'); }
#workspace #inspector li.fountain a:hover:after { background-image: url('../vendor/fountainjs/app/assets/images/toolbar/fountain-hover.png'); width: 98px; }
*/

/* notifications */
#workspace .notification::-moz-selection, #workspace .notification::selection { background: #454545; }
#workspace .notification
{
    background: #454545 url('../vendor/fountainjs/app/assets/images/notification.png') 6px 6px no-repeat;
    border-radius: 4px;
    color: #fff;
    display: none;
    font-size: 13px;
    padding: 6px 8px 6px 28px;
    position: absolute;
    right: 5px;
    top: -8px;
}

/* #51445F - moon warrior */
#workspace #script { margin: 25px auto 0 auto; width: 850px; }

#workspace .page::-moz-selection, #workspace .page::selection { background: #ED303C; color: #fff; }
#workspace #script .page
{
    background: #fff;
    border: 1px solid #d2d2d2;
    border-radius: 2px;
    color: #222;
    cursor: text;
    font: Courier,"Courier New",monospace;
    letter-spacing: 0 !important;
    font-family: 'Courier Final Draft', Courier, Courier New, monospace;
    line-height: 107.5%;
    margin-bottom: 25px;
    position: relative;
    text-align: left;
    width: 100%;
    z-index: 200;
    -webkit-box-shadow: 0 0 5px rgba(0,0,0,0.1);
	-moz-box-shadow: 0 0 5px rgba(0,0,0,0.1);
	box-shadow: 0 0 5px rgba(0,0,0,0.1);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

#workspace #script.dpi72 .page { font-size: 12px; padding: 72px 72px 72px 108px; }
#workspace #script.dpi100 .page { font-size: 16px; padding: 100px 100px 100px 150px; }
#workspace #script.dpi150 .page { font-size: 33px;  padding: 150px 150px 150px 225px; }

#workspace #script.dpi72 .page h1,
#workspace #script.dpi72 .page h2,
#workspace #script.dpi72 .page h3,
#workspace #script.dpi72 .page h4,
#workspace #script.dpi72 .page p { font-size: 12px; font-weight: normal; }

#workspace #script.dpi100 .page h1,
#workspace #script.dpi100 .page h2,
#workspace #script.dpi100 .page h3,
#workspace #script.dpi100 .page h4,
#workspace #script.dpi100 .page p { font-size: 17px; font-weight: normal; }

#workspace #script.dpi150 .page h1,
#workspace #script.dpi150 .page h2,
#workspace #script.dpi150 .page h3,
#workspace #script.dpi150 .page h4,
#workspace #script.dpi150 .page p { font-size: 33px; font-weight: normal; }

/* US Paper */
#workspace #script.us-letter.dpi72 { width: 612px; max-width: 100%; } /* us letter - 72 dpi - 612px x 792px - margins: 1" 1" 1" 1.5" */
#workspace #script.us-letter.dpi100 { width: 850px; max-width: 100%; } /* us letter - 100 dpi - 850px x 1100px - margins: 1" 1" 1" 1.5" */
#workspace #script.us-letter.dpi150 { width: 1275px; max-width: 100%; } /* us letter - 150 dpi - 1275px x 1650px - margins: 1" 1" 1" 1.5" */

#workspace #script.us-letter.dpi72 .page { min-height: 792px; } /* min height temporary until proper paging is implemented */
#workspace #script.us-letter.dpi100 .page { min-height: 1100px; } /* min height temporary until proper paging is implemented */
#workspace #script.us-letter.dpi150 .page { min-height: 1650px; } /* min height temporary until proper paging is implemented */

#workspace #script.us-letter.dpi72 .page.title-page { height: 792px; } /* temp for title page until paging is implemented */
#workspace #script.us-letter.dpi100 .page.title-page { height: 1100px; } /* temp for title page until paging is implemented */
#workspace #script.us-letter.dpi150 .page.title-page { height: 1650px; } /* temp for title page until paging is implemented */

/* A4 Paper */
#workspace #script.a4.dpi72 { width: 595px; } /* us letter - 72 dpi - 595px x 842px - margins: 1" 1" 1" 1.5" */
#workspace #script.a4.dpi100 { width: 827px; } /* us letter - 72 dpi - 827px x 1169px - margins: 1" 1" 1" 1.5" */
#workspace #script.a4.dpi150 {  width: 1250px; } /* us letter - 72 dpi - 1250px x 1754px - margins: 1" 1" 1" 1.5" */

#workspace #script.a4.dpi72 .page { height: 842px; }
#workspace #script.a4.dpi100 .page { height: 1169px; }
#workspace #script.a4.dpi150 .page { height: 1754px; }

/* title page */
#workspace #script .title-page h1 { margin-top: 50%; margin-bottom: 34px; text-align: center; width: 90.5%; }
#workspace #script .title-page p.credit { text-align: center; width: 90.5%; }
#workspace #script .title-page p.author,
#workspace #script .title-page p.authors { margin-bottom: 32px; margin-top: 0; text-align: center; width: 90.5%; }
#workspace #script .title-page p.source { margin-bottom: 32px; text-align: center; width: 90.5%; }
#workspace #script .title-page p.notes { bottom: 350px; position: absolute; right: 0; text-align: right; }
#workspace #script.dpi72 .title-page p.notes { bottom: 252px; right: 72px; }
#workspace #script.dpi100 .title-page p.notes { bottom: 350px; right: 100px; }
#workspace #script.dpi150 .title-page p.notes { bottom: 525px; right: 150px; }
#workspace #script .title-page p.date,
#workspace #script .title-page p.draft-date { bottom: 250px; position: absolute; right: 0; text-align: right; }
#workspace #script.dpi72 .title-page p.date,
#workspace #script.dpi72 .title-page p.draft-date { bottom: 180px; right: 72px; }
#workspace #script.dpi100 .title-page p.date,
#workspace #script.dpi100 .title-page p.draft-date { bottom: 250px; right: 100px; }
#workspace #script.dpi150 .title-page p.date,
#workspace #script.dpi150 .title-page p.draft-date { bottom: 375px; right: 150px; }
#workspace #script .title-page p.contact { bottom: 100px; position: absolute; right: 0; text-align: right; }
#workspace #script.dpi72 .title-page p.contact { bottom: 72px; right: 72px; }
#workspace #script.dpi100 .title-page p.contact { bottom: 100px; right: 100px; }
#workspace #script.dpi150 .title-page p.contact { bottom: 150px; right: 150px; }
#workspace #script .title-page p.copyright { bottom: 100px; position: absolute; text-align: left; }
#workspace #script.dpi72 .title-page p.copyright { bottom: 72px; }
#workspace #script.dpi100 .title-page p.copyright { bottom: 100px; }
#workspace #script.dpi150 .title-page p.copyright { bottom: 150px; }

/* script */
#workspace #script .page h2 { text-align: right; }
#workspace #script .page h2.left-aligned { text-align: left }
#workspace #script .page h3 { position: relative; }
#workspace #script .page h3:before { color: #bbb; content: attr(id); font-weight: bold; left: -45px; position: absolute; }
#workspace #script .page h3:after { color: #bbb; content: attr(id); font-weight: bold; right: -45px; position: absolute; }

#workspace #script .page div.dialogue { margin-left: auto; margin-right: auto; width: 68%; }
#workspace #script .page div.dialogue h4 { margin-bottom: 0; margin-left: 23%; }
#workspace #script .page div.dialogue p.parenthetical { margin-bottom: 0; margin-top: 0; margin-left: 11%;}
#workspace #script .page div.dialogue p { margin-bottom: 0; margin-top: 0; }

#workspace #script .page div.dual-dialogue { margin: 2em 0 0.9em 2%; width: 95%; }
#workspace #script .page div.dual-dialogue div.dialogue { display: inline-block; margin: 0; width: 45%; }
#workspace #script .page div.dual-dialogue div.dialogue h4 { margin-top: 0;}
#workspace #script .page div.dual-dialogue div.dialogue.right { float: right; }

#workspace #script .page p { }
#workspace #script .page p.centered { text-align: center; width: 92.5%; }

#workspace #script .page p.section { color: #bbb; margin-left: -30px; }
#workspace #script .page p.synopsis { color: #bbb; margin-left: -20px; }

#workspace #script .page span.italic { font-style: italic; }
#workspace #script .page span.bold { font-weight: bold; }
#workspace #script .page span.underline { text-decoration: underline; }

#workspace #script hr { border-color: #222 }
