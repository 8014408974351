// Link appearance styling
@mixin wrp-link-theme(
  $color: $link-color,
  $active-color: mix($color, color-yiq($color), 90%),
  $focus-background-color: $color,
  $focus-color: $color
) {
  color: $color;
  &:hover {
    color: $color;
  }
  &:active {
    color: $active-color;
  }
  &:focus {
    color: $focus-color;
    text-decoration: underline;
    outline: solid (2em / 16) $focus-background-color;
    outline-offset: 0;

    &:hover {
      color: $focus-color;
    }

    // stylelint-disable-next-line selector-class-pattern
    &:not(.focus-visible) {
      color: $color;
      background-color: transparent;
      outline: none;
    }
  }
}

// Abstract class for easily theming links
// without caring which class the mixin is included in
%wrp-link {
  @include wrp-link-theme;
}

$busy-link-background-position-y: calc(50% + 0.65em);
$busy-link-background-size-y: 3px;
@mixin busy-link {
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-repeat: no-repeat;
  background-position: top $busy-link-background-position-y left 0;
  background-size: 100% $busy-link-background-size-y;
  animation: busy-link 1s alternate infinite ease-in-out;
}

@keyframes busy-link {
  0% {
    background-size: 0% $busy-link-background-size-y;
  }

  50% {
    background-position: top $busy-link-background-position-y left 0;
    background-size: 100% $busy-link-background-size-y;
  }
  51% {
    background-position: top $busy-link-background-position-y right 0;
  }

  100% {
    background-position: top $busy-link-background-position-y right 0;
    background-size: 0% $busy-link-background-size-y;
  }
}

//
@mixin wrp-link {
  @extend %wrp-link;

  text-decoration: none;
  cursor: pointer;
  &:hover,
  &:active {
    text-decoration: underline;
  }

  &.wrp-busy {
    &,
    &:hover {
      text-decoration: underline;
    }

    @include busy-link;

    cursor: pointer;
  }
}

@mixin wrp-link--reversed {
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: underline;
  }
}

@mixin wrp-link--busy-inside {
  &.wrp-busy {
    background-image: none;

    span {
      @include busy-link;
    }
  }
}
