.wrp-image-responsive {
  width: auto;
  height: 50px;

  @include media-breakpoint-up(sm) {
    height: 75px;
  }

  @include media-breakpoint-up(md) {
    height: 100px;
  }
}

// stylelint-disable-next-line selector-no-qualifying-type
img.wrp-responsive-item {
  object-fit: cover;
}
