// stylelint-disable selector-class-pattern
.vjs-wrp-button {
  @include button-variant($dark, $dark);

  position: relative;
  display: inline-flex;
  flex: 1 1 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-family: $font-family-sans-serif-secondary;
  font-size: inherit;
  font-weight: normal;
  text-decoration: none;
  text-overflow: ellipsis;
  background-clip: padding-box;
  border: none;
  border-radius: 0;
  box-shadow: none;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    text-decoration: none;
    /* stylelint-disable-next-line declaration-no-important */
    box-shadow: none !important;
  }

  /* stylelint-disable no-descending-specificity */
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus,
  &.dropdown-toggle:focus,
  &:focus,
  &.focus {
    outline-color: transparent;

    &:not(:focus-visible) {
      box-shadow: none;
    }
    /* stylelint-enable no-descending-specificity */
  }

  &[aria-pressed] {
    opacity: 1; // Cancel out opacity change from being disabled
  }
}

.vjs-wrp-button--absolute {
  position: absolute;
  min-width: 25%;
  min-height: 25%;
}

.vjs-wrp-button--banner {
  min-width: 25%;
}

.js-choice-hotspot {
  border-radius: 0;
}

.invisible {
  opacity: 0;
}
/* stylelint-disable no-descending-specificity */
.vjs-wrp-button:not([disabled]):hover {
  visibility: visible;
  opacity: 1;
}
/* stylelint-enable no-descending-specificity */

.vjs-wrp-button.js-choice-hotspot:not(.js-choice-img) {
  opacity: 0;

  &:hover {
    opacity: 0;
  }

  &:disabled {
    opacity: 0;
  }

  &:focus {
    font-size: 0;
    color: transparent;
    text-indent: -10000px;
    background: transparent;
    outline: solid 2px transparent;
    outline-offset: -2px;

    // Use inset box shadow to ensure it is visible
    // even when the buttons are used in splits
    box-shadow: inset 0 0 0 $input-btn-focus-width theme-color(dark);
    opacity: 1;

    &:not(:focus-visible) {
      box-shadow: none;
      opacity: 0;
    }
  }
}

.js-choice-img {
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    height: auto;
    width: 50%;
    transform: translate(-50%, -50%);
    display: block;
  }

  /* Hide hover image by default */
  .js-image-hover {
    display: none;
  }

  /* If there's a hover image, hide base image on hover */
  &:hover .js-image-base.has-hover {
    display: none;
  }

  /* If there's a hover image, hide active image on hover */
  &:hover .js-image-active.has-hover {
    display: none;
  }

  /* If there's no hover image, do the scale transform */
  &:hover .js-image-base.no-hover {
    transform: scale(1.05) translate(-50%, -50%);
    transform-origin: left top;
  }

  /* Show hover image on hover */
  &:hover .js-image-hover {
    display: block;
  }

  /* Click adds js-image-hide, then this hides the hover image  */
  &:hover .js-image-hover.js-image-hide {
    display: none;
  }

  span {
    position: absolute;
    z-index: 1;
  }

  .js-image-hide {
    display: none;
  }
}

.toggle--active:hover .js-toggle-active-hover-hide {
  display: none;
}

// rule to make the image 100% width for custom button positions
// overriding the above because it's later in this sheet
.js-choice-img--custom-button-positions img {
  width: 100%;
}

// TODO: Find alternative solution to important styling
.vjs-wrp-button--img {
  /* stylelint-disable-next-line declaration-no-important */
  display: flex !important;
  align-items: center;
  justify-content: center;
  /* stylelint-disable-next-line declaration-no-important */
  overflow: visible !important;
  /* stylelint-disable-next-line declaration-no-important */
  background: transparent !important;
  /* stylelint-disable-next-line declaration-no-important */
  border-radius: 0 !important;
}

.vjs-wrp-button__text {
  z-index: 5;
  padding-right: 5%;
  padding-left: 5%;
  font-size: 0.75em;
  display: contents;
}
// stylelint-enable selector-class-pattern
