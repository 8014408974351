.wrp-content-group {
  padding: map-get($spacers, 4);
  background-color: $light;
  border-radius: 2 * $border-radius;

  > * {
    margin-bottom: 0;
  }

  > * + * {
    margin-top: map-get($spacers, 3);
  }

  // Slight margin adjustments
  // stylelint-disable selector-class-pattern
  > .string:first-child,
  > .text:first-child {
    margin-top: (-0.6rem);
  }
  // stylelint-enable selector-class-pattern
}

.wrp-content-group--no-border-top-radius {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.wrp-content-group--nested {
  background-color: darken($grey-lighter, 2%);
  padding: map-get($spacers, 3) map-get($spacers, 2);
  border-radius: 2 * $border-radius;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.wrp-content-group--white-bg {
  background-color: $white;
}

.wrp-content-group--footer {
  background-color: $grey-light;
  border-radius: 2 * $border-radius;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0.7rem map-get($spacers, 2);
}

.wrp-field-collapse-toggle-header-help {
  position: absolute;
  left: 150px;
  top: 5px;
}

.wrp-analytics-chart-tooltip-help {
  position: absolute;
  right: 0;
  top: 30px;
}

@include media-breakpoint-up(lg) {
  // stylelint-disable selector-class-pattern
  .sr-lg-only {
    @include sr-only;
  }
}
