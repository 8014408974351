@mixin project-errors-animation {
  $translation: -16.5em;
  &,
  .wrp-project-errors__toggle-text {
    transition: transform 0.35s ease-in-out;
  }

  .wrp-project-errors__toggle-text {
    // Needed by Firefox for transfor to apply;
    display: inline-block;
  }

  &:not(.wrp-project-errors--expanded) {
    transform: translate3d(#{$translation}, 0, 0);
    .wrp-project-errors__toggle-text {
      transform: translate3d(#{-$translation}, 0, 0);
    }
  }
}

$project-errors-border-radius: 0.9rem;
.wrp-project-errors {
  position: relative;
  color: $red;
  background: $red-light;
  border-top: solid 1px;
  border-right: solid 1px;
  border-bottom: solid 1px;
  border-top-right-radius: $project-errors-border-radius;
  border-bottom-right-radius: $project-errors-border-radius * 2;

  &--expanded {
    border-bottom-right-radius: $project-errors-border-radius;

    .wrp-project-errors__when-not-expanded {
      visibility: hidden;
      opacity: 0;
    }

    .wrp-project-errors__when-expanded {
      // Slight position adjustment to match the curvature
      top: 0.3rem;
      right: 0.3rem;
    }
  }

  > * {
    padding-right: map-get($spacers, 2);
    padding-left: map-get($spacers, 2);
  }

  .wrp-project-errors__toggle {
    width: 100%;
    text-align: left;
  }

  &:not(.wrp-project-errors--expanded) {
    .wrp-project-errors__when-expanded {
      visibility: hidden;
      opacity: 0;
    }
  }

  .wrp-project-errors__content {
    width: 25em;

    // Hooking on Bootstrap's classes
    // stylelint-disable-next-line selector-class-pattern
    &:not(.show) {
      display: block;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }

  @include project-errors-animation;
}

.wrp-project-errors-toggle {
  position: relative;
  padding: map-get($spacers, 2);
  padding-right: map-get($spacers, 4) * 1.5;

  > svg {
    position: absolute;
    top: map-get($spacers, 1);
    right: map-get($spacers, 1);
  }
}
