@import '~video.js/dist/video-js.min';

// https://fvsch.com/styling-buttons/#reset
.wrp-button-unstyled {
  padding: 0;
  font: inherit;
  color: inherit;
  background-color: transparent;
  border: none;
}
@import '~simplemde/dist/simplemde.min';
@import '~@simonwep/pickr/src/scss/themes/monolith';
@import '../vendor/fountainjs/app/assets/css/fountain-js';
// Import Shepherd before Boostrap, so the later can override the former
@import 'generic/shepherd';
@import 'generic/bootstrap';
@import 'generic/rythm';

th {
  font-weight: inherit;
}

select:not(.no-custom) {
  @extend .custom-select;
}

:last-child {
  margin-bottom: 0;
}

.wrp-object-fit-cover {
  object-fit: cover;
}

.wrp-stroke-current-color {
  stroke: currentColor;
}

.wrp-fill-current-color {
  fill: currentColor;
}

.wrp-centered-absolute-translate {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrp-whitespace-pre {
  white-space: pre;
}

// Helper to shift icons for better alignment with text
@each $pixels in (1) {
  .wrp-shift-down--relative-#{$pixels} {
    position: relative;
    top: 1em * $pixels / 16;
  }
}

// Disabling verification of classnames to hook onto classes
// used by Bootstrap (instead of rolling our own and updating simpleform)
// Also adding some bootstrap-like utilities
// stylelint-disable selector-class-pattern, declaration-no-important
.bg-danger-light {
  background-color: $red-light !important;
}

.alert-danger-light {
  color: $red !important;
  background-color: $red-light !important;
  border-color: $red-light !important;
}

.font-inherit {
  font: inherit !important;
}

.font-size-larger {
  font-size: (18rem / 16);
}

.font-size-normal {
  font-size: 1rem;
}

.font-size-smaller {
  font-size: (14rem / 16);
}

.font-size-badge {
  font-size: $badge-font-size;
}

.h4-font-size {
  font-size: $h4-font-size;
}

.mnw-50 {
  min-width: 50%;
}

// Helps constrain a bit of copy to a readable line-length
.mw-copy {
  max-width: 35rem;
}

.mw-none {
  max-width: none !important;
}

.cursor-default {
  cursor: default;
}

// A "soft" display inline block that plays nice with classes
// that override `display` for some reason
.d-inline-block--no-important {
  display: inline-block;
}

// Because Bootstrap does not provide a `flex: none` utility
.flex-none {
  flex: none !important;
}

.flex-auto {
  flex: auto !important;
}

.border-top-radius-0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

// Add a transparent outline that'll show in high contrast mode
// where the box-shadow showing the focus will get wiped out
.form-control,
.btn,
.custom-select,
// Add button and a to cater for the reset setting -webkit-focus-ring
button,
a {
  &:focus {
    outline: solid 2px transparent;
    outline-offset: 2px;
  }
}

// Ensure form-text plays nicely when combined with the `.badge` class
.form-text {
  line-height: 1.3;
  text-align: left;
  white-space: normal;

  a:not(.no-default) {
    text-decoration: underline;

    &,
    &:focus,
    &:active {
      color: inherit;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

// stylelint-enable selector-class-pattern, declaration-no-important

// Images, by default, shouldn't exceed the width of their parent container
img {
  max-width: 100%;
}

.wrp-stacking-context {
  position: relative;
  z-index: 0;
}

@each $zIndex in (0, 1, 2, 3) {
  .wrp-stacking-context__#{$zIndex} {
    position: relative;
    z-index: $zIndex;
  }
}

// stylelint-disable selector-class-pattern, declaration-no-important
// Embed for 4x3 screens.
// Used in dashboard.
.wrp-embed-four-by-three {
  @include media-breakpoint-down(sm) {
    height: 49vh;
    padding-top: 75% !important;
  }
}
// stylelint-enable selector-class-pattern, declaration-no-important
