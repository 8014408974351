@mixin loader-appearance(
  $progress-stroke-color: null,
  $track-stroke-color: null
) {
  circle {
    stroke: $track-stroke-color;
  }
  circle:nth-of-type(2) {
    stroke: $progress-stroke-color;
  }
}

@mixin loader-loading() {
  circle:nth-of-type(2) {
    animation: dashoffset-shift 3s ease-in-out infinite;
  }
}
