@use 'sass:color';
@use 'sass:map';

$island-line-height: 1.5rem;
$island-spacing-y: map.get($spacers, 3);
$island-text-color: $dark;

.wrp-island-sequence {
  margin-bottom: 0;
  font-size: 1.375rem;

  @include box-with-clamp(1);
}

.wrp-island {
  display: flex;
  flex-direction: column;
  justify-content: center;

  $offset: 0.25rem;

  // Leave a little space to not obscure the background rectangle's stroke
  width: calc(100% - #{$offset});
  height: calc(100% - #{$offset});
  padding: $island-spacing-y map.get($spacers, 3);

  // Center within the foreignObject
  margin: ($offset / 2) auto;
  font-size: (20rem / 16);
  line-height: $island-line-height;
  color: $island-text-color;
  text-align: center;

  // Mimic the border radius of the background rectangle
  border-radius: 0.5rem;

  > * {
    flex: none;
    margin-bottom: 0;
  }

  > * + * {
    margin-top: $island-spacing-y;
  }

  .wrp-island__variant-types {
    // Cancel out the text-align: center
    text-align: left;

    &:empty {
      display: none;
    }
  }

  .wrp-island__content {
    flex: 0 0 auto;

    @include box-with-clamp(2);
  }

  .wrp-island--with-variant-types {
    .wrp-island__sequence {
      // Because margins don't collapse in a flex container
      // and `gap` is not yet implemented throughout all browsers
      // we need to add some margin to the sequence at the top
      // of the island to balance the top margin above the variant types
      margin-bottom: 1rem;
    }
  }
}

$island-stroke-width: 1.5;
$island-stroke-color: $grey-accessible-control;

.joint-port-body {
  // Help use `.wrp-stroke-currentColor` and `.wrp-fill-currentColor`
  // when showing an icon
  color: $island-stroke-color;

  rect {
    fill: $light;
    stroke: $island-stroke-color;
    stroke-width: $island-stroke-width;
  }

  text {
    fill: currentColor;
  }

  &[aria-current] rect {
    fill: $grey-dark;
    stroke: $grey-dark;
  }

  &[aria-current] text {
    text {
      fill: $white;
    }
  }

  &:not(.joint-element):hover {
    opacity: 1;

    rect {
      fill: color.mix($light, $grey-dark, 80%);
    }

    text {
      fill: color.mix($grey, $grey-dark, 40%);
    }
  }

  &[aria-current] {
    &:not(.joint-element):hover {
      opacity: 1;

      /* stylelint-disable scss/no-global-function-names */
      rect {
        fill: darken($grey-dark, 15%);
      }

      /* stylelint-enable scss/no-global-function-names */

      text {
        fill: $white;
      }
    }
  }
}

.wrp-island-background {
  filter: drop-shadow(0 2px 4px rgba($black, 0.05));
  fill: $white;
  stroke: $island-stroke-color;
  stroke-width: $island-stroke-width;
}

.wrp-has-errors {
  .wrp-island-background {
    stroke: theme-color(danger);
  }

  .wrp-island {
    background-image: svg-load('../images/invalid-input.svg', #{'fill: #{theme-color(danger)}'});
    background-repeat: no-repeat;
    background-position: bottom 0.5rem right 0.5rem;
  }
}

.wrp-island-heading {
  font-size: (24rem / 16);
  font-weight: bold;
  line-height: 1.25;
  color: $dark;
  text-align: center;

  @include box-with-clamp(2);
}

.joint-type-island {
  &:hover {
    .wrp-island-background {
      stroke-width: 2 * $island-stroke-width;
      stroke: $orange;
    }
  }

  // Not using :focus-within as we need to be a little more specific
  &:focus-within {
    // .wrp-island {
    //   box-shadow: inset 0 0 0 3px #fff, inset 0 0 0 5px $island-stroke-color;
    // }

    // &.wrp-highlighted {
    //   .wrp-island {
    //     box-shadow: inset 0 0 0 3px #fff, inset 0 0 0 5px theme-color(primary);
    //   }
    // }
    .wrp-island-heading {
      text-decoration: underline;
    }

    // TODO: Handle focus visible if we end up using something else than `:focus-within`
  }

  &.wrp-highlighted {
    .wrp-island-background {
      stroke-width: 2.67 * $island-stroke-width;
      stroke: $light-purple;
    }
  }
}

.wrp-island-variant-types {
  &:empty {
    display: none;
  }

  > * + * {
    margin-left: map.get($spacers, 2);
  }
}
