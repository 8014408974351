.wrp-icon {
  box-sizing: content-box;
  width: (20em / 16);
  height: (20em / 16);
  background-color: $grey-light;
  border-radius: 1px;

  &--large {
    width: 2em;
    height: 2em;
  }

  &--medium {
    width: 1.75em;
    height: 1.75em;
  }

  &--blue {
    color: #0081ff;
  }

  &--no-background {
    background: none;
  }
}

// Little helper to adjust SVGs inline within some text
// `vertical-align: middle` would need a shift too
// so might as well skip it
.wrp-svg--in-text {
  position: relative;
  bottom: (2em / 16);
}
