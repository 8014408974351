[data-placeholder]:empty:not(:focus)::after {
  font-weight: normal;
  color: #5c738e;
  content: attr(data-placeholder);
}

.wrp-inplace-field {
  display: inline;
  margin: 0;
  font-weight: inherit;
  color: inherit;
  border: none;
  border: solid 1px $gray-500;
  outline: none;
  @include border-radius($input-border-radius, 0);
}

// stylelint-disable-next-line  selector-no-qualifying-type
textarea.wrp-inplace-field {
  width: 100%;
  height: 5.2rem;
  resize: none;
}

.wrp-edit-in-place {
  cursor: pointer;
}
