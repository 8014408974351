.wrp-notifications {
  &--toast {
    .wrp-notifications__container {
      position: relative;
      top: -10px;
      // Better support than "width: min-content"
      display: table;
      margin-inline: auto;
    }

    // stylelint-disable-next-line selector-class-pattern
    .alert {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      box-shadow: $shadow-grey-dark-20;
    }
  }

  &--absolute {
    position: relative;

    .wrp-notifications__container {
      position: absolute;
      left: 0;
    }
  }
}

// Disabling verification of classnames to hook onto classes
// used by Bootstrap
// stylelint-disable selector-class-pattern
.alert-success {
  color: $success-dark;
}

.alert-warning {
  color: $warning-dark;
}

@each $color-name, $color-value in $theme-colors {
  .alert-#{$color-name} {
    .wrp-loader {
      @include loader-appearance(currentColor, mix($color-value, $white, 50%));
    }
  }
}

.alert.small .close {
  padding: 0.2rem 1.25rem;
  font-size: 1.25rem;
}
// stylelint-enable selector-class-pattern
