a:not(.no-default) {
  @include wrp-link;
}

// Updating links in bootstrap alerts
// stylelint-disable-next-line selector-class-pattern
.alert a {
  // color: inherit;
  text-decoration: underline;

  &:hover {
    // color: inherit;
    text-decoration: underline;
  }
}

// stylelint-disable-next-line selector-class-pattern
.dropdown-item.active, // stylelint-disable-next-line selector-class-pattern
.dropdown-item:active {
  background-color: transparent;
}

.wrp-dropdown-item--cta {
  padding: 0;

  > a {
    width: 100%;
    font: inherit;
    text-align: left;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    padding-inline: $dropdown-item-padding-x;
    padding-block: $dropdown-item-padding-y;
  }

  // That's a long one for overriding Bootstrap's styles :'(
  // stylelint-disable-next-line selector-class-pattern
  .navbar-expand-lg .navbar-nav & .nav-link {
    padding-inline: $dropdown-item-padding-x + $navbar-nav-link-padding-x;
    padding-block: $dropdown-item-padding-y + $nav-link-padding-y;

    // Styling Bootstrap's API
    // stylelint-disable-next-line selector-class-pattern
    &.btn-outline-primary {
      color: theme-color(primary);

      // Massive specificity from needing to overidde a long selector
      // from Bootstrap
      // stylelint-disable-next-line selector-max-class
      &:hover {
        color: color-yiq(theme-color(primary));
      }
    }
  }
}

.wrp-link:not(.no-default) {
  @include wrp-link;
}

.wrp-link--underlined:not(.no-default) {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.wrp-link--busy-inside:not(.no-default) {
  @include wrp-link--busy-inside;
}

.wrp-link--reversed:not(.no-default) {
  @include wrp-link--reversed;
}

.wrp-link--muted:not(.no-default) {
  @include wrp-link-theme(
    $text-muted,
    $focus-background-color: theme-color(primary)
  );
}

.wrp-link--blended:not(.no-default) {
  &,
  &:hover,
  &:active,
  &:focus {
    font: inherit;
    color: inherit;
  }
  // stylelint-disable-next-line selector-class-pattern
  &:focus:not(.focus-visible) {
    font: inherit;
    color: inherit;
  }
}

.wrp-link--discreet:not(.no-default) {
  &,
  &:hover {
    font: inherit;
    color: inherit;
    text-decoration: inherit;
    cursor: inherit;
  }
}

// stylelint-disable-next-line selector-class-pattern
.nav-link:not(.no-default) {
  @include wrp-link--reversed;
}

.wrp-nav-link__help-svg > path {
  fill: $grey-dark;
}

// stylelint-disable-next-line selector-class-pattern
.nav-link:hover .wrp-nav-link__help-svg > path {
  fill: $dark;
}

.wrp-link--disabled:not(.no-default) {
  color: $btn-link-disabled-color;
  pointer-events: none;
}

.wrp-link--inverse {
  color: $light;

  &:hover {
    color: $grey-light;
  }
}
