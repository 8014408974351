// Disabling verification of classnames to hook onto classes
// used by Bootstrap (instead of rolling our own and updating simpleform)
@use 'sass:map';
@use 'sass:list';

@mixin button-focus($bg-color, $color: color-yiq($bg-color), $shadow-offset: 0, $shadow-before: null) {
  $focus-shadows: inset 0 0 0 (1px + $shadow-offset) $bg-color, inset 0 0 0 (3px + $shadow-offset) $color;

  @if $shadow-before {
    $focus-shadows: list.join($shadow-before, $focus-shadows, comma);
  }

  &:focus,
  &.focus {
    box-shadow: $focus-shadows;

    &:hover {
      box-shadow: $focus-shadows, $shadow-grey-dark-20;
    }
  }
}

@mixin button-small {
  font-size: 0.85rem;
  padding: 0.5rem;
}

@mixin button-outline {
  color: $grey-dark;
  background-color: $white;
  border-color: $grey-light;

  &:focus {
    border-color: theme-color(primary);
  }

  /* stylelint-disable max-line-length */
  @include button-focus($white, $color: theme-color(primary), $shadow-offset: 2px, $shadow-before: (null, inset 0 0 0 1px #{theme-color(primary)}));
  /* stylelint-enable */

  &:active {
    background-color: $grey-lighter;
  }

  &--primary {
    color: $primary;
    border-color: $primary;
  }

  &--purple {
    color: $purple;
    background-color: $white;
    border-color: $dark-purple;
  }

  .wrp-loader {
    @include loader-appearance($grey-dark, mix($grey-dark, $white, 30%));
  }
}

.btn {
  &:hover {
    box-shadow: $shadow-grey-dark-20;
  }

  // Restore focus outline for high contrast mode
  // As we're using box-shadow for focus indication
  &.focus,
  &:focus {
    outline: solid 2px transparent;
  }

  // All buttons have the same disabled state
  &:disabled,
  &.disabled {
    &,
    &:hover,
    &:active {
      color: $grey-dark;
      background-color: $grey-light;
      border-color: transparent;
    }

    &:hover {
      box-shadow: none;
    }
  }

  &--with-arrows {
    padding-bottom: 0.75rem;
    padding-left: 0.75rem;

    &::before {
      content: '';
      position: relative;
      display: inline-block;
      width: 30px;
      height: 18px;
      margin-left: 0.25rem;
      vertical-align: middle;
      /* stylelint-disable max-line-length */
      background-image: svg-load('../images/icons/icon-arrows-up-down-left-right-solid.svg', #{'fill: #{$dark-lighter}'});
      /* stylelint-enable */
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50%;
      border: 0;
    }

    &:hover {
      &::before {
        background-image: svg-load('../images/icons/icon-arrows-up-down-left-right-solid.svg', #{'fill: #{$white}'});
      }
    }
  }

  // Custom basic outline button
  &-outline {
    @include button-outline;
  }

  &-outline-warning {
    color: $stornaway-orange;
    border-color: $stornaway-orange;

    &:hover {
      background-color: $stornaway-orange;
      border-color: $stornaway-orange;
    }
  }

  &-small {
    @include button-small;

    &--with-play-icon {
      padding-bottom: 0.5rem;
      padding-left: 0.75rem;
      background-color: $dark-purple;
      color: $white;

      // TODO: Make this a mixin
      &::after {
        content: '';
        position: relative;
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-left: 0.25rem;
        vertical-align: top;
        background-image: svg-load('../images/icons/icon-play.svg', #{'fill: #{$white}'});
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50%;
        border: 0;
      }

      &:hover {
        color: $yellow;

        &::after {
          background-image: svg-load('../images/icons/icon-play.svg', #{'fill: #{$yellow}'}); // Change to yellow
        }
      }
    }
  }

  &-purple {
    color: $white;
    background-color: $purple;

    &:hover {
      color: $yellow;
      background-color: $dark-purple;
    }
  }

  // Set hover style for each button of the theme
  @each $color-name, $color-value in $theme-colors {
    &-#{$color-name} {
      $text-color: color-yiq($color-value);

      &:hover {
        color: $text-color;
        background-color: $color-value;
        border-color: $color-value;
      }

      @include button-focus($color-value);

      .wrp-loader {
        @include loader-appearance($text-color, mix($text-color, $color-value, 30%));
      }
    }
  }

  &-tight {
    padding: 0.5em 0.75em;
  }
}

.btn-gradient {
  &:focus {
    border-color: $dark;
  }

  &:not(:disabled) {
    &,
    &:hover {
      color: $dark;
      background: $button-gradient;
      background-origin: border-box;
    }
  }

  &--with-star-icon {
    padding-bottom: 2px;

    &::before {
      content: '';
      position: relative;
      bottom: 1px;
      display: inline-block;
      width: 15px;
      height: 15px;
      margin-right: map.get($spacers, 1);
      vertical-align: top;
      background-image: svg-load('../images/icon-star.svg', #{'fill: #{$dark}'});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 82%;
      border: 0;
    }
  }

  @include button-focus($yellow, $dark, 2px, (null, inset 0 0 0 1px #{$dark}));
}

.wrp-icon-button {
  position: relative;
  width: 2.5rem;
  padding: 0;

  &::before {
    display: block;
    width: 100%;
    padding-top: 100%;
    content: '';
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: $grey-dark;
  }
}

button:disabled .wrp-enabled-content,
button:not(:disabled) .wrp-disabled-content {
  display: none;
}

.wrp-loading-on-disable:disabled .wrp-loader,
.wrp-loading-on-disable.disabled .wrp-loader {
  @include loader-loading;
}
