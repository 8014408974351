@mixin cocoon-limit($scope-selector) {
  // Hides the add button when adding is no longer allowed
  .wrp-cocoon-limit--#{$scope-selector}.wrp-cocoon-limit--no-add {
    .wrp-cocoon-limit--#{$scope-selector}.wrp-cocoon-limit__add {
      display: none;
    }
  }

  // Hides the delete buttons when deleting is no longer allowed
  .wrp-cocoon-limit--#{$scope-selector}.wrp-cocoon-limit--no-delete {
    .wrp-cocoon-limit--#{$scope-selector}.wrp-cocoon-limit__delete {
      display: none;
    }
  }
}

@include cocoon-limit(variants);
@include cocoon-limit(choices);
