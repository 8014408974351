// stylelint-disable selector-class-pattern
.progress {
  // Make the progress bar sized to the surrounding font
  height: 1em;
  // Cancel out the font-size adjustment from Bootstrap
  // which should be on the stripes
  font-size: 1em;
  background: $white;
  border: solid 1px $grey-light;
}
.progress-bar {
  // Restore the font-size adjustment
  font-size: 0.75em;
}
.progress-bar-striped {
  @include gradient-striped($angle: -45deg);
}
// stylelint-enable selector-class-pattern
