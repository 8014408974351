// Uncomment to get visual cues of which player is being
// used when players are getting swapped
// @import './player/debug';

@mixin player-font-size(
  $property: font-size,
  $start-breakpoint: sm,
  $end-breakpoint: xl,
  $start-length: 0.75rem,
  $end-length: 1.75rem,
  $base-font-size: 16
) {
  #{$property}: $start-length;

  @include media-breakpoint-up($start-breakpoint) {
    $start-width: map-get($grid-breakpoints, $start-breakpoint);
    $end-width: map-get($grid-breakpoints, $end-breakpoint);

    // String otherwise SASS will try to evaluate and fail due to unit difference
    $current-width-from-start: '(100vw - #{$start-width})';
    // Need to sneak a 'px' in if the start-width is 0
    @if ($start-width == 0) {
      $current-width-from-start: '(100vw - #{$start-width}px)';
    }

    $width-difference: strip-unit($end-width - $start-width);
    $length-difference: strip-unit($end-length - $start-length);
    @if (unit($start-length) == 'rem') {
      $length-difference: $base-font-size * $length-difference;
    }

    // Prettier messes with the calc so wrapping it all in a string
    #{$property}: #{'calc(#{$start-length} + #{$length-difference} * #{$current-width-from-start} / #{$width-difference})'};
  }

  @include media-breakpoint-up($end-breakpoint) {
    #{$property}: $end-length;
  }
}

// Metrics to help position the button
$control-bar-spacing: 1.25vw;
$control-bar-bottom-offset: $control-bar-spacing;
$control-bar-height: (32rem / 16);
// Above 768px, the control bar gets larger
$control-bar-breakpoint: (768rem / 16);
// comment this out and test with same as small
$control-bar-height-large: (32rem / 16);

@mixin control-bar-offset(
  $property,
  $extra-offset: $control-bar-spacing,
  $extra-offset--large: $extra-offset
) {
  #{$property}: calc(
    #{$control-bar-bottom-offset} + #{$control-bar-height} + #{$extra-offset}
  );

  @media (min-width: #{$control-bar-breakpoint}) {
    #{$property}: calc(
      #{$control-bar-bottom-offset} + #{$control-bar-height-large} + #{$extra-offset--large}
    );
  }
}

@mixin control-bar-translate(
  $extra-offset: '0px',
  $extra-offset--large: $extra-offset,
  $translate-on-large-screen: true
) {
  transform: translateY(
    calc(
      #{$control-bar-bottom-offset} + #{$control-bar-height} + #{$extra-offset}
    )
  );

  @media (min-width: #{$control-bar-breakpoint}) {
    @if $translate-on-large-screen {
      transform: translateY(
        calc(
          #{$control-bar-bottom-offset} +
            #{$control-bar-height-large} +
            #{$extra-offset--large}
        )
      );
    } @else {
      transform: none;
    }
  }
}

// To override videoJS styles
// stylelint-disable selector-class-pattern
// TODO: This should really be the only thing necessary rather than the `vjs-wrp-control-bar-revealed` class
.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
  z-index: 0;
  opacity: 1;
  transition: visibility 0.3s, transform 0.3s;
  transition-timing-function: ease-in-out;
}

.video-js {
  position: relative;
  z-index: 0;
  padding: 0;

  .vjs-subs-caps-button > .vjs-icon-placeholder::before {
    font-size: 2.5em;
    line-height: 1em;
  }

  .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
    width: calc(100% + 70px);
    background: rgba(21, 29, 51, 0.8);
    border-radius: 5px;
  }

  .vjs-menu-button-popup .vjs-menu .vjs-menu-item {
    font-size: 1.4em;
  }

  .vjs-menu-button-popup .vjs-menu .vjs-menu-item-text {
    text-transform: capitalize;
  }

  .vjs-menu-button-popup .vjs-menu .vjs-texttrack-settings {
    display: none;
  }

  .vjs-menu-button-popup .vjs-menu .vjs-icon-placeholder::before,
  .vjs-menu-button-popup.vjs-hover .vjs-menu {
    display: none;
  }

  .vjs-control {
    margin: 0;
  }

  .vjs-control-bar {
    z-index: 2;
    padding: 0 0.5rem 0.5rem;
    opacity: 1;
    transition: visibility 0.3s, transform 0.3s;
    transition-timing-function: ease-in-out;
    background: linear-gradient(
      0deg,
      rgba(21, 29, 51, 0.35) 0%,
      rgba(21, 29, 51, 0) 100%
    );
    @include media-breakpoint-up(sm) {
      padding: 0 1rem 1rem;
      height: 4em;
    }
  }

  .vjs-volume-bar {
    margin: 1em;
  }

  // Hide the control bar by translating it down
  // VideoJS has highly specific selectors for controlling the display
  // of the control bar, so we need to bump up the specificity of our selectors
  // to make sure we win over them without having to repeat each selector from VideoJS
  &:not(.vjs-wrp-control-bar-revealed):not(.vjs-wrp-control-bar-revealed):not(
      .vjs-control-bar-revealed
    ),
  &.vjs-ended.vjs-ended.vjs-ended {
    .vjs-control-bar {
      @include control-bar-translate;
      visibility: hidden;
      opacity: 1;
      transition: visibility 0.3s, transform 0.3s;
      transition-timing-function: ease-in-out;
    }
  }

  // Shift the choices bar when bottom buttons or bottom banner
  // to it does not get overlaid by the buttons
  .vjs-wrp-choices-bar {
    transition: transform 0.3s;
    transition-timing-function: ease-in-out;
  }

  &:not(.vjs-wrp-control-bar-revealed),
  &[data-wrp-type='image'] {
    .vjs-wrp-choices-bar {
      &.bottom_buttons,
      &.bottom_banner {
        // Don't translate the bottom buttons so much
        // to allow the timer to show
        @include control-bar-translate($translate-on-large-screen: false);
        transform: translateY(calc(1.25vw + 1rem));
      }
    }
  }

  .vjs-big-play-button {
    @include button-variant($yellow, $yellow);
    z-index: 1;
  }

  .vjs-big-play-button:hover,
  &:hover .vjs-big-play-button {
    background: darken($yellow, 7.5%);
    border-color: darken($yellow, 7.5%);
  }

  .vjs-fullscreen-control,
  .vjs-mute-control {
    bottom: 0.8rem;
    > .vjs-icon-placeholder {
      font-size: 1rem;
    }
  }

  .vjs-wrp-control-logo-button {
    position: relative;
    width: 4.5rem;
    display: none;

    @include media-breakpoint-up(xs) {
      display: flex;
    }

    @include media-breakpoint-down(md) {
      margin-right: 0;
    }
  }

  .vjs-wrp-fullscreen {
    width: 1.2rem;
    margin-right: 0.75rem;
    cursor: pointer;

    &.hidden {
      display: none;
    }
  }

  .vjs-wrp-control-step-forward,
  .vjs-wrp-control-step-backward {
    width: 0.9rem;
    margin: auto 1rem;
    cursor: pointer;
  }

  .vjs-wrp-control-step-disabled {
    opacity: 0.5;
  }

  .vjs-wrp-control-step-backward {
    margin: 0 1rem 0 0.5rem;
  }

  .vjs-wrp-control-skip {
    width: 1.2rem;
    cursor: pointer;
  }

  .vjs-play-control {
    @include button-variant($yellow, $yellow);

    bottom: 1.5rem;
    width: 5rem;

    height: 3rem;
    padding: 0.8rem 1.5rem;
    margin-right: 1rem;
    border-radius: $video-controls-border-radius;

    @include media-breakpoint-down(sm) {
      bottom: 0;
      width: 0.5rem;
      height: 100%;
    }

    .vjs-icon-placeholder {
      font-size: 1rem;

      @include media-breakpoint-down(sm) {
        font-size: 0.6rem;
      }
    }
  }

  .vjs-play-control:focus {
    /* stylelint-disable declaration-no-important */
    background-color: $yellow !important;
    /* stylelint-enable declaration-no-important */
  }

  .vjs-progress-control {
    background: $dark;
    border-radius: $video-controls-border-radius;
    visibility: visible;
    min-width: auto;
  }

  .vjs-progress-holder,
  .vjs-play-progress {
    height: 0.8rem;
  }

  .vjs-progress-holder {
    background: $dark;
    border: 2px solid $grey-light;
  }

  .vjs-play-progress {
    background-color: $yellow;

    &::before {
      content: none;
    }
  }

  .vjs-load-progress {
    background: $grey;
  }

  &.vjs-wrp--swapping {
    .vjs-control-bar {
      visibility: hidden;
      opacity: 0;
      transition: visibility 1s, opacity 1s;
    }
  }

  .transparent {
    .vjs-wrp-choices-bar-prompt,
    .vjs-wrp-button,
    .vjs-wrp-button:first-of-type,
    .vjs-wrp-button:last-of-type {
      border-color: transparent;
      border-right: none;
      border-left: none;
    }
  }

  .bottom_banner.transparent {
    .vjs-wrp-choices-bar-prompt,
    .vjs-wrp-button,
    .vjs-wrp-button:first-of-type,
    .vjs-wrp-button:last-of-type {
      max-height: 12vh;
      padding-top: 3.5rem;
      padding-bottom: 6.7rem;
    }
  }

  // Removes unwanted controls (fullscreen, picture-in-picture, remaining time).
  // VideoJS was sometimes displaying these despite being disabled, this ensures they're not rendered to the user.
  .vjs-picture-in-picture-control,
  .vjs-remaining-time,
  .vjs-fullscreen-control {
    display: none;
    visibility: hidden;
  }
}

@import './player/choices_bar/choice';
@import './player/choices_bar';
@import './player/choices_bar/layouts';

.preload-player {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  visibility: hidden;
}

.js-wrp-video-hidden {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  visibility: hidden;
}

// Reveals control bar from the beginning.
// Embed player only.
.wrp-embed-player
  .video-js:not(.wrp-player-story-started):not(.preload-player)
  .vjs-control-bar {
  display: flex;
  visibility: visible;
  opacity: 1;
  transform: none;
}

.wrp-embed-player {
  .vjs-big-play-button {
    display: none;
    visibility: hidden;
  }
}

@import './player/text_overlay';
@import './player/image_player';
@import './player/subs_caps';
// stylelint-enable selector-class-pattern
