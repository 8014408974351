// Colors
$light: #f8f9fc;
$grey-lighter: #f4f8fb;
$grey-light: #dae2ed;
$grey: #8995a6;
$grey-accessible-control: #758493;
$grey-island: #56657a;
$grey-dark: #415571;
$dark-lighter: #30374b;
$dark: #151d33;
$blue: #0074e6;
$green: #44df7f;
$yellow: #ffcf00;
$dark-yellow: #f9b233;
$orange: #f59d0f;
$light-purple: #84f;
$purple: #5e11dd;
$dark-purple: #40c;
$red: #d72c19;
$red-light: #ffe5e5;
$success-light: #e4f8ee;
$success-dark: #1a5932;
$warning-dark: #604a0a;

// Stornaway Brand Colors
$stornaway-teal: #00b0af;
$stornaway-blue: #4814e0;
$stornaway-pink: #ff075f;
$stornaway-orange: #f59c1a;

// Gradients
$button-gradient: linear-gradient(157.5deg, $yellow 0%, $dark-yellow 50%, $orange 100%);
$purple-gradient: linear-gradient(157.5deg, $light-purple 0%, $purple 50%, $dark-purple 100%);

// Shadows
$shadow-blue-20: 0 2px 2px rgba($blue, 0.2);
$shadow-grey-dark-20: 0 2px 2px rgba($grey-dark, 0.2);

// Container
$studio-drawer-closed-overlap: 6vw;
$studio-drawer-closed-overlap-max-width: (80rem / 16);
$container-max-width-breakpoint: $studio-drawer-closed-overlap-max-width * 100 / 6;

// VideoJS
$video-controls-border-radius: 0.25rem;
