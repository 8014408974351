// Overriding bootstrap
// stylelint-disable-next-line selector-class-pattern
.tooltip-inner {
  max-width: (350rem / 16);
  padding: 0.75em;
  text-align: left;
}

// Overriding bootstrap
// stylelint-disable-next-line selector-class-pattern
.tooltip-inner a {
  color: inherit;
  text-decoration: underline;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.joint-tooltip__svg {
  &::after {
    content: '';
    position: relative;
    bottom: 1px;
    display: inline-block;
    width: 36px;
    height: 20px;
    margin-left: map-get($spacers, 2);
    vertical-align: top;
    background-image: svg-load(
      '../images/icon-game-controller.svg',
      #{'fill: #{$body-color}'}
    );
    background-repeat: no-repeat;
    background-position: center;
    background-size: 82%;
    border: 0;
  }
}
