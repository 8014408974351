.wrp-logo {
  width: (130rem / 16);
  height: auto;

  &--icon {
    width: 3rem;
  }

  &--large {
    width: (200rem / 16);
    height: auto;
  }
}

// Container for the main column of content
.wrp-container {
  width: calc(100% - #{2 * $studio-drawer-closed-overlap});
  margin-inline: auto;

  @media only screen and (min-width: 992px) {
    width: calc(100% - #{$studio-drawer-closed-overlap-max-width});
    margin-left: 2vw;
  }

  @media (min-width: $container-max-width-breakpoint) {
    width: calc(100% - #{1.5 * $studio-drawer-closed-overlap-max-width});
    margin-left: 3vw;
  }
}

@import '~select2';
@import '~select2-theme-bootstrap4';

@import 'components/card';
@import 'components/flashes';
@import 'components/icons';
@import 'components/loader';
@import 'components/links';
@import 'components/buttons';
@import 'components/tooltips';
@import 'components/dropdown';
@import 'components/container';
@import 'components/tooltip_with_modal';
@import 'components/notifications';
@import 'components/banners';
@import 'components/badges';
@import 'components/collapse';
@import 'components/progress';
@import 'components/file_upload';
@import 'components/visual';
@import 'components/navbar';
@import 'components/forms';
@import 'components/tabs';
@import 'components/cocoon_limit';
@import 'components/footer';
@import 'components/modal';
@import 'components/modal_upload_video';
@import 'components/island';
@import 'components/variant_types';
@import 'components/sidebar_form';
@import 'components/settings_sections';
@import 'components/filter-bar';
@import 'components/color_picker';
@import 'components/script_preview';
@import 'components/video';
@import 'components/watch';
@import 'components/script_editor';
@import 'components/project_errors';
@import 'components/editable';
@import 'components/payments';
@import 'components/images';
@import 'components/timer';
@import 'components/conditional_link_field';
@import 'components/walkthrough';
@import 'components/plan';
@import 'components/tables';
@import 'components/select2_select';
