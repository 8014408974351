.wrp-sidebar-form {
  display: flex;
  flex-direction: column;
  height: 100%;

  > * {
    flex: none;
  }

  .wrp-sidebar-form__fields {
    flex: auto;
    overflow: auto;
  }

  .wrp-sidebar-form__padded {
    padding-right: map-get($spacers, 3);
    padding-left: map-get($spacers, 3);
  }

  &__legend--tooltip {
    display: inline-block;
    width: auto;
  }

  .wrp-sidebar-form__delete {
    display: table; // Limit the width to max-content
  }

  // Disabling verification of classnames to hook onto classes
  // used by Bootstrap
  // stylelint-disable selector-class-pattern, declaration-no-important
  .mt-4,
  .my-4 {
    margin-top: 1rem !important;
  }

  .rythm-5 > * + * {
    margin-top: 2rem !important;
  }
  // stylelint-enable selector-class-pattern, declaration-no-important
}

.behaviour-block-fields {
  .behaviour-fields {
    &:first-of-type {
      .remove_fields {
        display: none;
      }
    }
  }
}

.conditions-wrapper {
  .condition-fields {
    &:first-of-type {
      .remove_fields {
        display: none;
      }
    }
  }
}

.condition-groups-wrapper {
  .condition-group-fields {
    &:first-of-type {
      .condition-group--logical-statement--input-wrapper {
        display: none;
      }
    }

    .condition-fields {
      &:first-of-type {
        .condition--logical-statement--input-wrapper {
          display: none;
        }
      }
    }
  }
}
