$checkbox-box-size: (24rem / 16);

@mixin checkbox-label(
  $box-size: $checkbox-box-size,
  $padding-left: calc(#{$box-size} + 0.5em),
  $offset-y: 0
) {
  @include sr-only;

  + label {
    position: relative;
    padding-left: $padding-left;
    user-select: none;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: '';
    }

    &::before {
      top: 50%;
      left: 0;
      width: $box-size;
      height: $box-size;
      margin-top: $offset-y;
      border: solid 1px;
      border-radius: 5px;
      transform: translateY(-50%);
    }

    &::after {
      top: 50%;
      left: $box-size / 2;
      box-sizing: border-box;
      width: 12px;
      height: 7px;
      margin-top: $offset-y;
      border-bottom: solid 3px;
      border-left: solid 3px;
      border-bottom-left-radius: 2.5px;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &:not(:checked) + label::after {
    opacity: 0;
  }

  &:not(:disabled) + label {
    cursor: pointer;
  }
}

@mixin checkbox-appearance {
  + label {
    &::before {
      background-color: $input-bg;
      border-color: $input-border-color;
    }

    &::after {
      border-color: color-yiq(theme-color(primary));
    }
  }

  // Disabled state
  &:disabled + label {
    color: $grey-dark;

    &::before {
      opacity: 0.8;
    }
  }

  &:disabled:checked + label {
    &::after {
      opacity: 0.75;
    }
  }

  // Focus state
  &:focus + label {
    &::before {
      outline: solid 2px transparent;
      outline-offset: 2px;
      box-shadow: 0 0 0 1px $white, 0 0 0 3px theme-color(primary);
    }
  }

  // Hover states
  &:not(:checked):not(:disabled) + label:hover {
    &::after {
      opacity: 0.7;
      transform: translate(-50%, -50%) scale(0.85);
    }
  }

  &:checked:not(:disabled) + label:hover {
    &::after {
      opacity: 0.7;
      transform: translate(-50%, -50%) scale(1.15);
    }
  }
}
