.wrp-filter-bar {
  display: flex;
  flex-wrap: wrap;

  &__search {
    form {
      display: flex;
      // Prevents Safari from stretching element.
      height: (44rem / 16);
    }

    @include media-breakpoint-down(sm) {
      display: block;
    }

    @include media-breakpoint-down(lg) {
      flex-basis: 100%;
      width: 100%;
      margin-right: 0;
      border: 0;
      margin-bottom: 1rem;
    }

    @include media-breakpoint-up(xl) {
      padding-right: 0.5rem;
      border-right: 1px solid $grey;
    }
  }

  &__item {
    @include media-breakpoint-down(sm) {
      flex-basis: 100%;
      margin-bottom: 1rem;
      margin-left: 0;
      display: block;
    }

    &:not(.wrp-filter-bar__search) {
      @include media-breakpoint-up(md) {
        flex-basis: 48%;
        margin-bottom: 10px;
      }

      @include media-breakpoint-up(lg) {
        flex-basis: 49%;
        margin-bottom: 10px;
      }

      @include media-breakpoint-up(xl) {
        width: auto;
        flex-basis: auto;
        display: flex;
        justify-content: space-evenly;
        margin-left: 0;
      }
    }
  }

  .wrp-filter-bar__search ~ .wrp-filter-bar__item:nth-child(2) {
    padding-left: 0;

    @include media-breakpoint-up(xl) {
      padding-left: 0.5rem;
    }
  }
}
