// Disabling verification of classnames to hook onto classes
// used by Bootstrap (instead of rolling our own and updating simpleform)
// stylelint-disable selector-class-pattern
@use 'sass:map';

legend {
  margin-bottom: 0;
  font: inherit;

  + * {
    margin-top: map.get($spacers, 2);
  }

  // Adds a bit of hierarchy to the forms
  // .col-form-label is used by SimpleForm
  // to mark legends of radiogroups
  &:not(.col-form-label) {
    font-weight: bold;
  }
}

label {
  margin-bottom: map.get($spacers, 1);
}

.form-control {
  .was-validated &:invalid,
  &.is-invalid {
    background-color: $red-light;

    &:focus {
      box-shadow: 0 0 0 1px theme-color(danger);
    }
  }
}

.wrp-optional-flag .form-control-label.optional:not(.wrp-hide-optional-label)::after {
  color: #6a7780; // Slightly lighter version of text-muted that remains accessible
  content: ' - optional';
}

// We already add a focus ring, so we can safely remove Firefox's dashed focus indicator
// other browsers are taken care of with outline-color: transparent;
.custom-select::-moz-focus-inner {
  display: none;
}

.form-check {
  padding-left: 0;

  + * {
    margin-top: 0.5rem;
  }
}

[type='checkbox'] {
  @include checkbox-label();
  @include checkbox-appearance();

  + label {
    &::after {
      width: $checkbox-box-size;
      height: $checkbox-box-size;
      background-image: svg-load('../images/checkbox-check.svg', #{'fill: #{theme-color(primary)}'});
      background-position: center;
      background-size: 100% auto;
      border: 0;
      transform: translate(-50%, -50%);
    }
  }
}

[type='radio'] {
  @include radio-label();
  @include radio-appearance();
}

.wrp-inline-checkboxes {
  display: flex;
  flex-wrap: wrap;

  > * {
    flex: 1 1 1%;
    margin-top: 0;
  }
}

.wrp-field-collapse-toggle {
  svg {
    transition: transform 0.3s ease-in-out;
    transform: rotate(270deg);
  }

  &[aria-expanded='true'] {
    svg {
      transform: rotate(90deg);
    }
  }
}

.wrp-badge {
  &--word-wrap {
    text-align: left;
    white-space: normal;
  }
}
// stylelint-enable selector-class-pattern

.wrp-url-input {
  width: 30rem;
  max-width: 100%;
}

.wrp-password-toggle {
  position: absolute;
  top: 0;
  right: calc(0.375em + 0.1875rem);
  bottom: 0;
  width: calc(0.85em + 0.375rem);
  background-image: svg-load('../images/visibility.svg', #{'fill: #{$grey-dark}'});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  border: none;
  outline: none;

  &[role='button'] {
    cursor: pointer;
  }
}

[type='text'] ~ .wrp-password-toggle {
  background-image: svg-load('../images/visibility-off.svg', #{'fill: #{$grey-dark}'});
}

// stylelint-disable-next-line selector-class-pattern
.form-control.is-invalid ~ .wrp-password-toggle {
  // move it over to so it doesn't overaly the invalid input image
  right: calc(0.375em + 1.875rem);
}

// for the custom button position percentage fields in the form
.short-input {
  width: 10ch;
  margin-right: 5px;
  text-align: center;
}
