/* stylelint-disable max-line-length */
$colors: dark $dark, stornaway-teal $stornaway-teal, stornaway-blue $stornaway-blue, stornaway-pink $stornaway-pink, stornaway-orange $stornaway-orange;
/* stylelint-enable max-line-length */

.settings-section {
  @each $color-name, $color-value in $colors {
    &__title--#{$color-name} {
      color: $white;
      background-color: $color-value;
      padding: 0.5rem 1rem;
      border-top-left-radius: 1.5 * $border-radius;
      border-top-right-radius: 1.5 * $border-radius;

      @if $color-name == 'stornaway-orange' {
        color: $dark;
      }
    }

    &__container--#{$color-name} {
      border: 1px solid $color-value;
      border-radius: 2 * $border-radius;
    }
  }
}

.variant-media-type {
  display: flex;
  flex-direction: column;
}

.variant-media-type label {
  padding: 1rem 2rem 1rem 3rem;
  margin-bottom: 10px;
  border: 1px solid;
  border-radius: 0.375rem;
  cursor: pointer;
  background-color: $white;
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    background-color: #f3f4f6;
  }

  &::before {
    left: 1rem;
  }

  &::after {
    left: 1.75rem;
  }
}

.variant-media-type input {
  display: none;
}
