// stylelint-disable selector-class-pattern
.bottom_banner {
  width: 100%;

  .vjs-wrp-choices-bar-prompt {
    border-radius: 0;

    &:only-child {
      margin: 7.5% 0;
    }
  }

  .vjs-wrp-choices-bar-prompt ~ .vjs-wrp-button {
    border-radius: 0;
  }

  .js-choice-img img {
    /* stylelint-disable-next-line declaration-no-important */
    top: 35% !important;
  }

  @media screen and (max-width: 888px) {
    padding-bottom: 0.5em;
  }
}

.bottom_banner--tall_banner_buttons {
  .vjs-wrp-button {
    padding-top: 1.5rem;
    padding-right: 1%;
    @include control-bar-offset(padding-bottom, 1rem);
    padding-left: 1%;
  }
  .vjs-wrp-choices-bar__container {
    margin: 0;
  }
}

.bottom_buttons {
  width: 100%;

  .vjs-wrp-choices-bar__container {
    width: 100%;
  }

  .vjs-wrp-choices-bar-prompt {
    margin-bottom: 1%;
    border-radius: $video-controls-border-radius * 4;
  }

  .vjs-wrp-button {
    margin-top: 1%;
    margin-bottom: 0;
    /* stylelint-disable-next-line declaration-no-important */
    border-radius: 2rem !important;

    + .vjs-wrp-button {
      margin-left: 1%;
    }
  }

  .btn {
    padding: 0.75em 1em;
  }
}

.right_hand_text {
  align-items: flex-start;
  justify-content: flex-end;

  .vjs-wrp-choices-bar-prompt {
    padding: 1rem 0.7rem;
    border-radius: 0;
  }

  .vjs-wrp-button {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: inherit;
  }

  .vjs-wrp-choices-bar__container {
    flex-direction: column;
    width: auto;
    min-width: 0;
    max-width: 40%;
    height: 100%;
    margin-right: 0;
  }
}

.split_top_bottom {
  //Half split style top/bottom
  .vjs-wrp-button--absolute {
    top: 0;
    width: 100%;
    height: 50%;

    &.top {
      top: 0;
    }
    &.bottom {
      top: 50%;
    }
  }
}

.split_left_right {
  //Half split style left/right
  .vjs-wrp-button--absolute {
    top: 0;
    width: 50%;
    height: 100%;

    &.left {
      left: 0;
    }
    &.right {
      left: 50%;
    }
  }
}

.split_quadrant {
  .vjs-wrp-button--absolute {
    max-width: 50%;
    max-height: 50%;
    border-radius: 50px;
    position: absolute;

    // TODO: Repeated in lots of places,
    // could be a mixin instead
    &.top {
      &_left {
        top: 25%;
        left: 25%;
        transform: translate(-50%, -50%);
      }
      &_right {
        top: 25%;
        right: 25%;
        transform: translate(50%, -50%);
      }
    }

    &.bottom {
      &_left {
        bottom: 25%;
        left: 25%;
        transform: translate(-50%, 50%);
      }
      &_right {
        right: 25%;
        bottom: 25%;
        transform: translate(50%, 50%);
      }
    }
  }
}

.split_quadrant.text_split_quadrant {
  .vjs-wrp-button--absolute {
    width: 50%;
    height: 50%;
    border-radius: 0;
    transform: none;

    &.top {
      &_left {
        top: 0;
        left: 0;
        position: absolute;
      }
      &_right {
        top: 0;
        left: 50%;
        position: absolute;
      }
    }

    &.bottom {
      &_left {
        top: 50%;
        left: 0;
        position: absolute;
      }
      &_right {
        top: 50%;
        left: 50%;
        position: absolute;
      }
    }
  }
}

.split_quadrant.button_split_quadrant {
  // Quadrant styles (BUTTON VARIANT)
  .vjs-wrp-button--absolute {
    min-width: 20%;
    min-height: 0;
    padding: 0.75rem;
    border-radius: 25px;
  }

  .top {
    &_left {
      top: 35%;
      left: 25%;
      transform: translate(-50%, -50%);
    }

    &_right {
      top: 35%;
      right: 25%;
      transform: translate(50%, -50%);
    }
  }

  .bottom {
    &_left {
      bottom: 15%;
      left: 25%;
      transform: translate(-50%, 50%);
    }

    &_right {
      right: 25%;
      bottom: 15%;
      transform: translate(50%, 50%);
    }
  }

  .js-choice-hotspot {
    padding-top: 12%;
    border-radius: 0;

    &.top {
      &_left {
        top: 25%;
        left: 25%;
      }
      &_right {
        top: 25%;
        right: 25%;
      }
    }

    &.bottom {
      &_left {
        bottom: 25%;
        left: 25%;
      }
      &_right {
        right: 25%;
        bottom: 25%;
      }
    }
  }
}

.split_quadrant.banner_split_quadrant {
  // Quadrant styles (BANNER VARIANT)
  .vjs-wrp-button--absolute {
    top: inherit;
    width: 50%;
    min-height: 18%;
    padding-bottom: 3%;
    border-radius: 0;
    transform: none;

    &.top {
      &_left {
        bottom: 50%;
        left: 0;
      }
      &_right {
        bottom: 50%;
        left: 50%;
      }
    }

    &.bottom {
      &_left {
        bottom: 0;
        left: 0;
      }
      &_right {
        bottom: 0;
        left: 50%;
      }
    }
  }
}

.new_split_left_right {
  .vjs-wrp-choices-bar__container {
    // Using grid rather than flex as it allows for better
    // alignment control
    display: grid;
    grid-template-columns: repeat(var(--number-of-choices, 4), 1fr);
  }

  &[data-substyle='central_button'] {
    .vjs-wrp-choices-bar__container {
      height: auto;
      padding: 1%;
      margin: auto;
      gap: 2%;
    }

    .vjs-wrp-button {
      border-radius: $video-controls-border-radius * 4;
    }

    &[data-number-of-choices='1'] {
      .vjs-wrp-choices-bar__container {
        justify-items: center;
      }
    }
  }
}

.new_split_top_bottom {
  .vjs-wrp-choices-bar__container {
    display: grid;
    justify-content: stretch;
    grid-template-rows: repeat(var(--number-of-choices, 4), 1fr);
  }

  &[data-substyle='central_button'] {
    .vjs-wrp-choices-bar__container {
      align-items: center;
      justify-content: center;
      width: auto;
      padding: 1%;
      margin-right: auto;
      margin-left: auto;
      gap: 5%;

      @include control-bar-offset(padding-bottom, 1rem);
    }
    .vjs-wrp-button {
      border-radius: $video-controls-border-radius * 4;
    }
    img {
      /* stylelint-disable-next-line declaration-no-important */
      max-width: unset !important;
    }
  }
}

.custom_button_positions {
  .vjs-wrp-choices-bar-prompt {
    margin-bottom: 1%;
    border-radius: $video-controls-border-radius * 4;
  }

  .vjs-wrp-button {
    border-radius: 1.5em;

    @media (max-width: 520px) {
      border-radius: 1em;
    }

    @media (min-width: 521px) and (max-width: 1420px) {
      border-radius: 1.3em;
    }

  }

  .btn {
    padding: 0; // Override Bootstrap to let button be smaller than 7% width
  }

  // Responsive styles to make custom text buttons stay approx same size at different screen widths
  .vjs-wrp-button__text {
    font-size: 0.6em;
    line-height: 1.2em;
    padding: 1em 1em;
    display: block;
    
  @media (max-width: 399px) {
    padding: 1em 0.5em;
  }

  @media (min-width: 400px) and (max-width: 520px) {
    padding: 1.25em 1em;
  }

  }
}
// TODO: Consider defining breakpoints as SASS variables for consistency across stylesheets.

// stylelint-enable selector-class-pattern
