@use 'sass:map';

.wrp-tabs {
  position: relative;
  z-index: 0;
  display: flex;
  counter-reset: tabIndex;
  border-bottom: 0;

  > * {
    flex: 1 1 1%;
  }

  > * + * {
    margin-left: map.get($spacers, 2);
  }

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    height: 1px;
    content: '';
    background: $border-color;
  }

  // Styling Bootstrap
  // stylelint-disable selector-class-pattern
  .active {
    position: relative;
    z-index: 2;
  }

  // For when the tabs need a + button on the side
  > .nav-tabs {
    flex: 0 1 auto;
    border: 0;
  }
  // stylelint-enable selector-class-pattern

  > :not(.wrp-tabs__no-counter) {
    counter-increment: tabIndex;

    &::after {
      content: counter(tabIndex);
    }
  }

  &--lower-roman {
    > :not(.wrp-tabs__no-counter) {
      &::before {
        content: counter(tabIndex, lower-roman) ': ';
      }

      &::after {
        content: none;
      }
    }
  }
}

@mixin wrp-tab-background($fill: $white, $stroke: $border-color) {
  // stylelint-disable prettier/prettier
  background-image:
    svg-load('../images/tab-background-left.svg', #{'fill: #{$fill}'}, #{'stroke: #{$stroke}'}),
    svg-load('../images/tab-background-middle.svg', #{'fill: #{$fill}'}, #{'stroke: #{$stroke}'}),
    svg-load('../images/tab-background-right.svg', #{'fill: #{$fill}'}, #{'stroke: #{$stroke}'});
  // stylelint-enable prettier/prettier
}

.wrp-tab {
  $text-color: $grey-dark;
  $image-width: map.get($spacers, 3) / 1rem * 1em;

  &:not(.wrp-tab--no-max-width) {
    max-width: (150em / 16);
  }

  overflow: hidden;

  // https://fvsch.com/styling-buttons/#reset
  font: inherit;
  color: $text-color;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;

  // Defaut the background to transparent, any fill will come from the image
  background-color: transparent;

  // Only repeat the central part
  background-repeat: no-repeat, repeat-x, no-repeat;

  // Offset the sides to account for the border
  background-position: top 0 left -1 * $image-width, center, top 0 right -1 * $image-width;

  // Clip them accordingly
  background-clip: border-box, padding-box, border-box;

  // Scale them so they take the whole height
  // With a little larger background for the main fill
  // so it doesn't show white lines on Chrome (probs due to px rounding)
  background-size: auto 100%, 2em 100%, auto 100%;

  // Vertical spacing is controlled by padding so it doesn't affect background-clip
  border-block: 0;
  padding-block: map.get($spacers, 2) / 1rem * 1em;

  // But horizontal padding is set through transparent borders so the center background gets clipped
  border-inline: solid $image-width transparent;

  // Set the 3 parts of the background
  @include wrp-tab-background;
  @include wrp-link--reversed;

  // Styling Bootstrap
  // stylelint-disable-next-line selector-class-pattern
  &.active {
    font-weight: bold;
    color: inherit;
  }

  &:hover {
    &::-moz-focus-inner {
      display: none;
    }

    text-decoration: underline;
  }

  &:focus {
    color: color-yiq(theme-color(primary));

    // Cancel some of the link styles
    background-color: transparent;
    outline-color: transparent;

    @include wrp-tab-background(theme-color(primary), theme-color(primary));
  }

  // stylelint-disable-next-line selector-class-pattern
  &:focus:not(.focus-visible) {
    color: $text-color;

    @include wrp-tab-background;
  }

  &--filled {
    @include wrp-tab-background($fill: $border-color);
  }

  // stylelint-disable selector-class-pattern
  &--light-when-active {
    &.active:not(:focus),
    &.active:focus:not(.focus-visible) {
      @include wrp-tab-background($fill: $light);
    }
  }
  // stylelint-enable selector-class-pattern

  &--has-error {
    position: relative;

    &::before {
      $size: 0.625em;
      $margin: $size / 2;

      padding-left: $size + $margin;
      content: '';
      background-image: radial-gradient(theme-color(danger) 50%, transparent calc(50% + 1px));
      background-repeat: no-repeat;
      background-position: center left;
      background-size: $size $size;
    }
  }

  &.wrp-tab--no-md-flex-grow {
    @include media-breakpoint-up(md) {
      flex: none;
    }
  }
}

// Following Bootstrap utility naming
// stylelint-disable-next-line selector-class-pattern
.mt-tabs {
  margin-top: (34rem / 16);
}
