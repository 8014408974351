// Foundation's strip-unit function
/// Removes the unit from a numeric value
///
/// @param {Number} $value - The value to strip unit from
///
/// @return {Number} The number, without its unit
@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@import '~bootstrap/scss/functions';
@import 'abstract/variables';
@import 'abstract/variables-bootstrap';

// Extra variables dependent on Bootstrap
$iframe-modal-bg: rgba($white, 0.8);
$js-modal-bg: rgba($grey-lighter, 1);
$iframe-modal-filter: drop-shadow(0 2px 4px rgba($dark, 0.05));

@import '~bootstrap/scss/mixins';
@import 'abstract/mixins/checkbox';
@import 'abstract/mixins/line-clamp';
@import 'abstract/mixins/radio';
@import 'abstract/mixins/links';
@import 'abstract/mixins/loader';
