// Controls the state of the file-upload
// showing and hiding elements depending on its state
// through __hidden_when_<state> or __hidden_when_not_<state>
.wrp-file-upload {
  .wrp-file-upload__empty {
    display: block;
    width: 100%;
  }

  &.wrp-file-upload--empty {
    .wrp-file-upload__preview {
      display: none;
    }
    .wrp-file-upload-placeholder {
      display: flex;
    }
  }

  &:not(.wrp-file-upload--empty) {
    .wrp-file-upload__empty {
      display: none;
    }
  }

  &.wrp-file-upload--with-selection {
    .wrp-file-upload__hidden-when-with-selection {
      display: none;
    }
  }

  &:not(.wrp-file-upload--with-selection) {
    .wrp-file-upload__hidden-when-not-with-selection {
      display: none;
    }
  }

  &.wrp-file-upload--is-uploading {
    .wrp-file-upload__hidden-when-uploading {
      display: none;
    }
  }

  &:not(.wrp-file-upload--is-uploading) {
    .wrp-file-upload__hidden-when-not-uploading {
      display: none;
    }
  }

  &.wrp-file-upload--is-removing {
    .wrp-file-upload__hidden-when-removing {
      display: none;
    }

    .wrp-file-upload-preview__thumbnail {
      &::before {
        position: relative;
        z-index: 1;
        background-color: rgba($dark, 0.25);
      }
    }
  }

  &:not(.wrp-file-upload--is-removing) {
    .wrp-file-upload__hidden-when-not-removing {
      display: none;
    }
  }

  &--placeholder {
    .wrp-file-upload-preview {
      display: block;

      > * + * {
        margin-top: 1rem;
      }
    }

    $placeholder-height: 10rem;

    .wrp-file-upload-placeholder {
      display: none;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: $placeholder-height;
      background-color: $grey-lighter;
      border: 1px solid $grey-light;
      border-radius: 3px;
      svg {
        fill: $grey;
      }
    }

    .wrp-file-upload-preview__thumbnail {
      min-height: $placeholder-height;
    }

    .wrp-file-upload-actions {
      // Ensure there's no shift when button gets replaces by the action
      margin-top: 2rem;
      margin-bottom: 1.8rem;
    }
  }

  &--has-errors {
    // stylelint-disable-next-line selector-class-pattern
    .progress-bar {
      background: theme-color(danger);
    }
  }
}

.wrp-file-upload-preview {
  > * {
    display: block;
    width: 100%;
  }
  > * + * {
    margin-top: map-get($spacers, 2);
  }
  $breakpoint-preview-inline: 400px;
  @media (min-width: ($breakpoint-preview-inline)) {
    @supports (display: grid) {
      display: grid;
      align-items: center;
      column-gap: map-get($spacers, 3);
      grid-template-columns: 10em 20em;
      row-gap: map-get($spacers, 1);

      > * {
        grid-column: 2;
      }

      > * + * {
        margin: 0;
      }

      > .wrp-file-upload-preview__thumbnail {
        border: solid 1px $input-border-color;
        grid-column: 1;
        grid-row: 1 / span 2;
      }

      &--no-thumbnail {
        // Swap to 1 full width column when no thumbnail is there
        grid-template-columns: 100%;

        > * {
          // And shift everything to the first column
          grid-column: 1;
        }
      }
    }
  }
}

.wrp-file-upload-actions {
  $breakpoint-actions-inline: 375px;
  @media (max-width: ($breakpoint-actions-inline - 0.02)) {
    > * {
      &,
      > * {
        display: block;
        width: 100%;
      }
    }

    > * + *:not(.no-separator) {
      padding-top: map-get($spacers, 2);
      margin-top: map-get($spacers, 2);
      // TODO: Review appearance, separator is a bit tall
      border-top: solid 1px $text-muted;
    }
  }

  @media (min-width: $breakpoint-actions-inline) {
    > * + *:not(.no-separator) {
      padding-left: map-get($spacers, 2);
      margin-left: map-get($spacers, 2);
      // TODO: Review appearance, separator is a bit tall
      border-left: solid 1px $text-muted;
    }
  }
}

.wrp-file-upload-progress {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  padding: 1em (map-get($spacers, 4) / 1rem * 1em)
    (map-get($spacers, 4) / 1rem * 1em);
  color: $grey-dark;
  background-color: $grey-light;
  border-radius: $border-radius;

  .wrp-file-upload-progress__label {
    width: 100%;
  }

  .wrp-file-upload-progress__bar {
    flex: auto;
  }

  .wrp-file-upload-progress__button {
    flex: none;
    margin-left: map-get($spacers, 2);
  }
}

* + .wrp-file-upload-filename:not(:empty) {
  &::before {
    content: '- ';
  }
}
