$theme-colors: (
  primary: $blue,
  secondary: $dark,
  tertiary: $orange,
  danger: $red,
  success: $green,
  info: $blue,
  pronounced: $grey-island,
  subtle: $grey,
  dark: $dark-lighter,
);

$body-bg: $light;
$body-color: $grey-dark;
$border-color: $grey-light;

$font-size-base: 1rem !default;
$font-family-sans-serif: 'Lato', sans-serif;
$font-family-sans-serif-secondary: 'Raleway', sans-serif;

$h1-font-size: $font-size-base * 1.75 !default;
$h2-font-size: $font-size-base * 1.313 !default;
// Enable and set these when needed in markup.
$h3-font-size: $font-size-base * 1.125 !default;
$h4-font-size: $font-size-base * 1.6 !default;
// $h5-font-size: $font-size-base * 1.25 !default;
// $h6-font-size: $font-size-base !default;

$link-color: #3f73af;

$btn-padding-x: 1.5em;
$btn-padding-y: 1em;
$btn-font-size: (14em / 16);
$btn-line-height: 1.1;
$btn-font-weight: bold;

$navbar-padding-x: 0;
$navbar-light-color: $grey-dark;
$navbar-light-hover-color: $dark;
$navbar-light-active-color: $grey-dark;
$navbar-light-disabled-color: $grey-lighter;
$navbar-light-toggler-border-color: $grey-dark;

$modal-backdrop-bg: $grey-light;
$modal-backdrop-opacity: 0.2;

$input-border-color: $grey-accessible-control;
$input-box-shadow: none;

$input-disabled-color: $grey-dark;
$input-disabled-bg: $grey-light;

$input-focus-border-color: theme-color(primary);
$input-focus-box-shadow: 0 0 0 1px $input-focus-border-color;

$input-group-addon-bg: $grey-light;

$alert-bg-level: -9;
$alert-border-radius: 0;

$form-feedback-icon-invalid: svg-load(
  '../images/invalid-input.svg',
  #{'fill: #{theme-color(danger)}'}
);

$custom-select-bg-size: 9px 5px;
$custom-select-indicator: svg-load(
  '../images/chevron-down.svg',
  #{'fill: #{$body-color}'}
);
$custom-select-disabled-color: $input-disabled-color;
$custom-select-disabled-bg: $input-disabled-bg;
$custom-select-focus-box-shadow: $input-focus-box-shadow;

$progress-border-radius: 999999px;
$progress-height: 3rem;

$table-striped-order: even;

$table-head-bg: $grey-light;
$table-head-color: inherit;
$table-accent-bg: $grey-lighter;

$tooltip-bg: $dark;
$tooltip-color: $light;

$modal-backdrop-bg: $dark;
$modal-backdrop-opacity: 0.5;
$modal-inner-padding: 0.5rem;

$embed-responsive-aspect-ratios: (
  (21 9),
  (16 9),
  (3 2),
  (4 3),
  (1 1),
  (2 1),
  (185 1),
  (239 1)
);

@import '~bootstrap/scss/variables';

$table-head-border: $border-color;

// Disable highlighting of valid state
$form-validation-states: map-remove($form-validation-states, 'valid');
