$radio-size: (24rem / 16);
$radio-dot-size: 0.5 * $radio-size;

@mixin radio-label(
  $size: $radio-size,
  $dot-size: $radio-dot-size,
  $padding-left: calc(#{$size} + 0.5em),
  $offset-y: 0
) {
  @include sr-only;

  + label {
    position: relative;
    padding-left: $padding-left;
    user-select: none;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: '';
    }

    &::before {
      top: 50%;
      left: 0;
      width: $size;
      height: $size;
      margin-top: $offset-y;
      border: solid 1px;
      border-radius: 99999px;
      transform: translateY(-50%);
    }

    &::after {
      top: 50%;
      left: $size / 2;
      box-sizing: border-box;
      width: $dot-size;
      height: $dot-size;
      margin-top: $offset-y;
      border: solid ($dot-size / 2);
      border-radius: 99999px;
      transform: translate(-50%, -50%);
    }
  }

  &:not(:checked) + label::after {
    opacity: 0;
  }

  &:not(:disabled) + label {
    cursor: pointer;
  }
}

@mixin radio-appearance() {
  + label {
    &::before {
      background-color: $input-bg;
      border-color: $input-border-color;
    }
    &::after {
      border-color: theme-color(primary);
    }
  }

  // Disabled state
  // Use disabled attribute instead of :disabled pseudo-class
  // Workaround for: https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/11295231
  // Thx bootstrap source https://github.com/twbs/bootstrap/blob/f6694b74405261ed454d409ea5251f08cdf6c51c/scss/forms/_form-check.scss#L67
  &:disabled + label {
    color: $gray-600;

    &::before {
      opacity: 0.8;
    }
  }

  &:disabled:checked + label {
    &::after {
      opacity: 0.75;
    }
  }

  &:checked + label {
    border-color: theme-color(primary);
  }

  // Hover states
  // Clicking a radio on hover doesn't untick it just need to hover when unchecked
  &:not(:checked):not(:disabled) + label:hover {
    &::after {
      opacity: 0.7;
      transform: translate(-50%, -50%) scale(1.15);
    }
  }

  // Clicking a radio button that's already checked doesn't do anything so there should be no hover

  // Focus state
  &:focus + label {
    &::before {
      outline: solid 2px transparent;
      outline-offset: 2px;
      box-shadow: 0 0 0 1px $white, 0 0 0 3px theme-color(primary);
    }
  }
}
