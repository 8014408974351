.wrp-payments-price {
  // Center the content
  display: flex;
  flex-direction: column;
  justify-content: center;

  // Ensure a minimum width
  // and height for consistem sizing
  width: 7rem;
  min-height: 7rem; // min-height for Safari
  margin-left: auto;
  font-size: 80%;
  font-weight: bold;
  color: #34394a;
  text-align: center;
  border: solid 2px;
  border-radius: 9999px;
  margin-inline: auto;

  &__price {
    font-size: 3em;
    line-height: 1;

    + * {
      margin-top: 0.125em;
    }
  }

  &__group {
    display: block;
    font-weight: normal;
    line-height: 1.2;
  }

  &__custom-price {
    font-size: 2em;
    line-height: 1;
  }

  &--on-request {
    > * {
      font-size: 150%;
      font-weight: bold;
    }
  }
}
