$shepherd-bg: $white !default;
$shepherd-color: $body-color !default;
$shepherd-border-radius: $tooltip-border-radius !default;

// Overriding styles of Shepherd.js library so disabling the selector pattern
// stylelint-disable selector-class-pattern
.shepherd-element {
  background: transparent;
  filter: $iframe-modal-filter;
  box-shadow: none;
}

.shepherd-content {
  padding: map-get($spacers, 3);
  // @include font-size($tooltip-font-size);
  font-size: 1rem;
  // Taken from Bootstrap's `.tooltip-inner` styling
  color: $shepherd-color;
  text-align: center;
  word-wrap: break-word;
  background: $shepherd-bg;

  // Taken from Bootstrap's `.tooltip` styling
  @include reset-text();
  @include border-radius($shepherd-border-radius);

  > * {
    padding: 0;
    margin-bottom: 0;

    + * {
      margin-top: map-get($spacers, 3);
    }
  }

  h1 {
    font-size: $h2-font-size;
  }
}

.shepherd-text {
  padding: 0;
  margin-top: map-get($spacers, 1);
  font: inherit;
  color: inherit;

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  > * {
    padding: 0;
  }

  > * + * {
    margin-top: map-get($spacers, 2);
  }
}

.shepherd-cancel-icon {
  // Nicer visual alignment
  margin-top: -1 * map-get($spacers, 1);
  margin-right: -1 * map-get($spacers, 1);
  color: inherit;
  opacity: 0.75;

  &:hover {
    color: inherit;
    opacity: 1;
  }

  &:focus {
    outline-color: $primary;
  }
}

.shepherd-modal-overlay-container {
  fill: $dark;
}

@include media-breakpoint-down(md) {
  // Need to override inline styles set by shepherd
  // stylelint-disable declaration-no-important
  .shepherd-element {
    position: fixed !important;
    top: auto !important;
    right: 1rem !important;
    bottom: 0 !important;
    left: 1rem !important;
    width: auto;
    max-width: none;
    transform: none !important;

    &:not(.wrp-walkthrough-step--create-island) {
      .shepherd-content {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  // Create island step would be behind the popup
  // so we need to shift it up a little
  .wrp-walkthrough-step--create-island {
    margin-bottom: 9rem !important;
  }
  // stylelint-enable declaration-no-important

  .shepherd-arrow {
    display: none;
  }
}

// stylelint-enable selector-class-pattern
