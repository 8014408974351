/* stylelint-disable selector-class-pattern, no-descending-specificity */
.vjs-wrp-choices-bar {
  // Position the bar relative to the bottom of the player
  // to facilitate positioning
  position: absolute;
  bottom: 0;

  // Don't set any height as not all styles require 100% height;

  width: 100%;

  @include player-font-size;

  &.bottom_buttons {
    @include control-bar-offset(padding-bottom);

    padding-inline: 2.5%;

    // Pull the timer closer from the control bar when after it
    > * + * {
      margin-top: 1vw;
    }
  }

  /* Only consider clicks on the button and links */
  pointer-events: none;

  a,
  button {
    pointer-events: auto;
  }

  &.split_quadrant,
  &.split_top_bottom,
  &.split_left_right,
  &.new_split_left_right,
  &.new_split_top_bottom,
  &.right_hand_text {
    display: flex;
    height: 100%;

    .vjs-wrp-choices-bar__container {
      width: 100%;
      margin: 0;
    }

    // Hide question on section layouts
    .vjs-wrp-choices-bar-prompt {
      display: none;
    }
  }
}

.vjs-wrp-choices-bar--custom-button-positions {
  height: 100%;
  z-index: 1; // Puts the choice above the overlay text
}

.js-image-base--custom-button-positions {
  width: 100%;
}

.js-image-hover--custom-button-positions {
  width: 100%;
}

.js-image-active--custom-button-positions {
  width: 100%;
}

.vjs-wrp-choices-bar__container {
  position: relative;

  // Puts the choice above the overlay text
  // as it needs lifting up to be above
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.vjs-wrp-choices-bar__custom-button-positions-container {
  // This needs to unset .vjs-wrp-choices-bar__container position
  // To enable the buttons to be freely positioned
  position: unset;

  .vjs-wrp-choices-bar-prompt {
    margin: 1rem;
  }
}

.vjs-wrp-choices-bar-prompt {
  width: 100%;
  padding: 1% 0;
  margin: 0;
  font-family: $font-family-sans-serif-secondary;
  line-height: 1.2;
  text-align: center;
  background: $dark;
  border-radius: $video-controls-border-radius $video-controls-border-radius 0 0;
}

.wrp-watch-page-wrapper-aspect-9by16 {
  .vjs-wrp-choices-bar.bottom_buttons {
    padding-bottom: 20vw;
  }

  .vjs-wrp-choices-bar {
    font-size: 1.7em;
  }
}

/* stylelint-enable selector-class-pattern, no-descending-specificity */
