// A common abstract class to gather common properties
$variant-type-icon-size: (15em / 14);
%wrp-variant-type {
  // Fallback
  display: inline-block;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: (85em / 14);
  padding: map-get($spacers, 2);
  font-size: (14rem / 16);
  // Make the text the same height as the icons for better alignment
  line-height: (15 / 14);
  color: $grey-island;
  text-align: center;
  background-color: $grey-light;
  border-radius: $border-radius / 2;

  &::before {
    // Fallback
    display: inline-block;
    width: $variant-type-icon-size;
    height: $variant-type-icon-size;
    margin-right: map-get($spacers, 2);
    vertical-align: middle;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
  }
}

// The icons are sized according to the circle
// as Safari wreaks avoc if trying to scale them
// via `transform` so we need to use the background-size
$variant-type-scale: (
  start: 1,
  end: 1 / 1.25,
  /* Same for the restart*/ restart: 1.1 / 1.25,
);

@each $variant-type in (start, end, restart) {
  .wrp-variant-type--#{$variant-type} {
    @extend %wrp-variant-type;
    &::before {
      background-image: svg-load(
        '../images/variant-#{$variant-type}.svg',
        #{'fill: #{$grey-island}'}
      );
      // Little size adjustments
      background-size: 100% * map-get($variant-type-scale, $variant-type);
    }
  }
}
