.wrp-navbar {
  background: $white;
  box-shadow: 0 1px 5px 1px rgba($grey, 0.2);
  // Prepare ground for the studio
  border-block: solid 1px transparent;

  button:focus {
    outline-color: $primary;

    &:not(:focus-visible) {
      outline-color: transparent;
    }
  }

  .wrp-navbar__shift-down {
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: (3rem / 16);
    }
  }
}
