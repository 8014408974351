@use 'sass:map';

.wrp-loader {
  transform: rotate(-90deg);
  transform-origin: center;

  @include loader-appearance(theme-color(primary));

  svg {
    width: (20em / 16);
  }
}

.template-loader {
  height: 100vh;
}

.wrp-loader--bold {
  circle {
    stroke-width: 30;
  }

  circle:nth-of-type(2) {
    stroke-width: 40;
  }
}

.wrp-loader--small {
  width: 0.8rem;
}

.wrp-loader--loading {
  @include loader-loading;
}

.wrp-loader--dark {
  @include loader-appearance($yellow);

  .wrp-loader-with-label__label {
    color: $light;
  }
}

.wrp-loader-with-label {
  text-align: center;

  .wrp-loader-with-label__label {
    margin-top: map.get($spacers, 3);
  }

  @supports (display: grid) {
    display: grid;
    max-width: (224rem / 16);

    > * {
      grid-column: 1;
      grid-row: 1;
    }

    .wrp-loader-with-label__label {
      // Align vertically in the center
      align-self: center;

      // Cancel out unecessary margin
      margin-top: 0;

      // Add a bit of space on the sides to not colide with the circle
      margin-inline: map.get($spacers, 4);
    }
  }
}

@keyframes dashoffset-shift {
  0% {
    stroke-dashoffset: 705;
  }

  2% {
    stroke-dashoffset: 705;
  }

  // Add a little pause in the middle
  48% {
    stroke-dashoffset: 1410;
  }

  52% {
    stroke-dashoffset: 1410;
  }

  98% {
    stroke-dashoffset: 2115;
  }

  100% {
    stroke-dashoffset: 2115;
  }
}
