$dropdown-arrow-offset: 1rem;
$dropdown-arrow-width: (20rem / 16);
// Disabling verification of classnames to hook onto classes
// used by Bootstrap
// stylelint-disable selector-class-pattern
.dropdown-toggle {
  &::after {
    position: relative;
    bottom: 1px;
    display: inline-block;
    width: 5px;
    height: 9px;
    margin-left: map-get($spacers, 2);
    vertical-align: middle;
    background-image: svg-load(
      '../images/chevron-right.svg',
      #{'fill: #{$navbar-light-color}'}
    );
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    border: 0;
    transform: rotate(-90deg);
  }

  &:hover::after {
    background-image: svg-load(
      '../images/chevron-right.svg',
      #{'fill: #{$navbar-light-hover-color}'}
    );
  }

  &[aria-expanded='true']::after {
    transform: rotate(90deg);
  }
}

.dropdown-menu {
  margin-top: 0.125rem;
  margin-right: -$dropdown-arrow-offset;
  border: 0;
  border-radius: 0;

  @include media-breakpoint-up(sm) {
    box-shadow: 0 2px 20px 2px rgba(0, 0, 0, 0.1);
  }

  &::after {
    position: absolute;
    right: $dropdown-arrow-offset;
    bottom: 100%;
    display: block;
    content: '';
    border: solid $dropdown-arrow-width / 2 transparent;
    border-bottom-color: $dropdown-bg;
  }

  &[x-placement*='top'] {
    // Space the menu out a bit from the `top`
    // position it's being given by JavaScript
    margin-top: -0.625rem;

    &::after {
      top: 100%;
      bottom: auto;
      border-top-color: $dropdown-bg;
      border-bottom-color: transparent;
    }
  }
}

.dropleft .dropdown-menu {
  &::after {
    position: absolute;
    top: $dropdown-arrow-offset;
    bottom: auto;
    left: 100%;
    border-bottom-color: transparent;
    border-left-color: $dropdown-bg;
  }
}

.wrp-navbar__dropdown-menu {
  right: 0.5rem;
  left: auto;
}

// stylelint-enable selector-class-pattern
