.tooltip_with_modal {
  .dropdown-menu {
    padding: 0.75em;
    font-size: 0.875rem;
    color: $white;
    border-radius: 0.25rem;
    background-color: rgba($dark, 0.9);
    min-width: 12rem;
    a:not(.no-default) {
      color: $white;
      text-decoration: underline;
    }
  }

  .dropdown-menu-left.dropdown-menu::after {
    left: 0.8rem;
  }

  .dropdown-menu-right.dropdown-menu::after {
    right: 1.7rem;
  }

  .dropdown-menu::after {
    right: auto;
    border-bottom-color: rgba($dark, 0.9);
    color: rgba($dark, 0.9);
    border-width: 0.4rem;
  }

  .dropdown-menu[x-placement*='top'] {
    &::after {
      border-top-color: rgba($dark, 0.9);
      border-width: 0.4rem;
      border-bottom-color: transparent;
      color: rgba($dark, 0.9);
    }
  }
}
