.wrp-plan {
  // Overriding Bootstrap's tab styles
  // stylelint-disable selector-class-pattern
  &,
  &.active {
    display: flex;
  }
  // stylelint-enable selector-class-pattern
  flex-direction: column;
  font-size: (14rem / 16);
  padding-inline: map-get($spacers, 3);
  padding-block: map-get($spacers, 4);

  > * {
    margin-bottom: 0;
  }

  > * + * {
    margin-top: map-get($spacers, 5);
  }
}

.wrp-plan-tabs {
  // Make the tabs expand the height of their
  // tabpanel to fill the whole height, allowing
  // to expand the card furthermore
  display: flex;
  flex-direction: column;

  &__tablist {
    flex: none;
  }

  &__tabpanels {
    // Will make the content stretch the whole height
    // more reliably than with `height`/`min-height` (on Safari, especially)
    display: flex;
    flex: auto;
  }

  &__card {
    flex: auto;
  }
}

.wrp-plan-list {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  > * {
    flex: 1 1 1%;
    min-width: (260rem / 16);
  }

  // Ensure padding on each side of the list
  &::before,
  &::after {
    display: block;
    flex: none;
    width: $grid-gutter-width / 2;
    content: '';
  }
}

// Container for the plans
.wrp-container-plans {
  max-width: (1440rem / 16);
  // Remove padding so it is handled by the list itself
  // because of padding getting removed at end of horizontal
  // scrolling areas
  padding: 0;
}
