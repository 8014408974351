$text-overlay-line-height: 1.5;
// Use a generic selector to make creation of parts
// on the JS side lighter, while still allowing to target
// specific parts for styling
[class*='wrp-player-text-overlay-part'] {
  font-size: 0.75em;
  border-radius: (5rem / 16);
}

.wrp-watch-page-wrapper-aspect-9by16 .wrp-player-text-overlay-content {
  font-size: 1.6em;
}

.wrp-player-text-overlay-part--title {
  font-size: 1.2em;
  font-weight: 700;
}

.wrp-player-text-overlay-content {
  padding: 1.5%;

  overflow: auto;

  font-family: $font-family-sans-serif-secondary;
  font-size: 0.75rem;
  font-size: var(--sw-text-overlay-font-size);
  line-height: 1.4;
  color: $black;
  text-align: center;
  background: rgba($white, 0.8);
  border-radius: 0.25rem;
  // Store the font size in a custom property so
  // we can reuse it to provide spacing that's independent
  // from each element's font size
  @include player-font-size(--sw-text-overlay-font-size);

  > * {
    margin-bottom: 0;
  }

  > * + * {
    margin-top: 0.75rem;
    margin-top: calc(0.5 * var(--sw-text-overlay-font-size));
  }
}

.wrp-player-text-overlay {
  // Group positioning properties
  // stylelint-disable-next-line no-duplicate-selectors
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  inset: 0;
  a,
  button {
    pointer-events: auto;
  }

  > * {
    // Position any overlay above the wrapper for the choices
    // Choices will then be set above those
    z-index: 1;
    width: 80%;
    max-width: 35em;
    max-height: 87%;
    overflow: auto;
  }

  &[data-prompt-style='bottom_banner'],
  &[data-prompt-style='bottom_buttons'] {
    padding-bottom: 22.5%;
  }

  // Reduce the width for the right_hand_text
  // to avoid running under the choices
  &[data-prompt-style='right_hand_text'] {
    > * {
      width: 60%;
    }
  }
}
